import { Intent } from '@blueprintjs/core';
import * as React from 'react';
import Callout from 'reports/components/core/controls/Callout';

const UpgradeToProReportControls = () => (
    <Callout intent={Intent.WARNING}>
        <span>
            Custom reports and financial analysis are early access features that are not included in your current plan.
            If you would like full access to these features, please{' '}
            <a href="mailto:support@helioscope.com" target="_blank">
                contact us
            </a>
            .
        </span>
    </Callout>
);

export { UpgradeToProReportControls };
