import React from 'react';

import { ProjectBase } from 'frontend/projects/project-base';
import { BaseProjects } from 'frontend/projects/base';
import { ProjectOverview } from 'frontend/projects/project-overview';
import { Projects } from 'frontend/projects/projects';
import { Simulations } from 'frontend/projects/simulations';
import { Sharing } from 'frontend/projects/sharing';

import { AppStore } from 'reports/store';

import { loadBookmarkedReports, loadFullReport, loadProject } from 'frontend/routing/loaders';
import { ProjectReports } from 'frontend/projects/project-reports';
import { ProjectReportViewer } from 'frontend/projects/project-report-viewer';
import { ProjectReportConfigEditor } from 'frontend/projects/project-report-config-editor';
import { ProjectReportBase } from 'frontend/projects/project-report-base';

const projectsRoutes = (store: AppStore) => ({
    element: <BaseProjects />,
    children: [
        {
            path: 'projects',
            element: <Projects />,
        },
        {
            path: 'projects/:projectId',
            element: <ProjectBase />,
            loader: async ({ params }) => await loadProject(store, params.projectId),
            children: [
                {
                    index: true,
                    element: <ProjectOverview />,
                },
                {
                    path: 'reports',
                    element: <ProjectReports />,
                    loader: async () => await loadBookmarkedReports(store),
                },
                {
                    path: 'report/:slug',
                    element: <ProjectReportBase />,
                    loader: async ({ params }) => await loadFullReport(store, params.projectId, params.slug),
                    children: [
                        {
                            index: true,
                            element: <ProjectReportViewer />,
                        },
                        {
                            path: 'configure',
                            element: <ProjectReportConfigEditor />,
                        },
                    ],
                },
                {
                    path: 'simulation',
                    element: <Simulations />,
                },
                {
                    path: 'sharing',
                    element: <Sharing />,
                },
                {
                    path: 'designer/:designId/*',
                    element: <div>Designer Placeholder</div>,
                },
            ],
        },
    ],
});

export { projectsRoutes };
