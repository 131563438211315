import superagent from 'superagent';
import superagentUse from 'superagent-use';
import { StoreContainer } from 'reports/utils/store_container';

function defaultHeaderPlugin(req: superagent.Request) {
    req.set(DEFAULT_HEADERS);
    return req;
}

// @ts-ignore
const _request = superagentUse(superagent);
_request.use(defaultHeaderPlugin);

// Current FL custom request headers
const HEADERS = {
    impersonateUser: 'IMPERSONATE_USER',
    overridePermissions: 'OVERRIDE_PERMISSIONS',
    logRocketUrl: 'LOG_ROCKET_URL',
    hsClient: 'HS_CLIENT',
};

const DEFAULT_HEADERS = {};

function addDefaultHeader(header: string, value: any) {
    DEFAULT_HEADERS[header] = value;
}

function removeDefaultHeader(header: string) {
    delete DEFAULT_HEADERS[header];
}

function hasDefaultHeader(header: string) {
    return header in DEFAULT_HEADERS;
}

type HTTPMethod = 'GET' | 'PUT' | 'POST' | 'PATCH' | 'HEAD' | 'DELETE';

const requestFn: {
    [k in HTTPMethod]: (url: string) => superagent.SuperAgentRequest;
} = {
    GET: _request.get,
    PUT: _request.put,
    POST: _request.post,
    PATCH: _request.patch,
    HEAD: _request.head,
    DELETE: _request.delete,
};

// Redirect to login page on 401 error
_request.use((req: superagent.Request) => {
    req.on('error', (err: any) => {
        if (err.status === 401) {
            const config = StoreContainer.store!.getState().config.serverConfig!;
            const reqUrl = err?.response?.req?.url;
            const urlRoot = config.url_root;
            const primaryUrlRoot = config.primary_url_root;

            if (
                reqUrl &&
                (reqUrl.startsWith(`${urlRoot}/api/`) ||
                    reqUrl.startsWith(`${primaryUrlRoot}/api/`) ||
                    reqUrl.startsWith('/api/')) &&
                !reqUrl.includes('/users/login') // Don't redirect for login requests
            ) {
                window.location.href = primaryUrlRoot ? `${primaryUrlRoot}/?login` : `/?login`;
            }
        }
    });
    return req;
});

// superagent use doesn't replace the callable part of the superagent api
const request: superagent.SuperAgentStatic = Object.assign(
    (method: HTTPMethod, url: string) => requestFn[method](url),
    _request,
);

export { request, superagent, addDefaultHeader, removeDefaultHeader, hasDefaultHeader, HTTPMethod, HEADERS };

export default request;
