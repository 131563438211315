import * as React from 'react';
import { Card } from 'reports/components/core/containers';
import classNames from 'classnames';
import { Button } from 'reports/components/core/controls';
import { Intent, Classes, Tag, Switch, Alignment } from '@blueprintjs/core';
import { api as externalCredentialsApi } from 'reports/models/external_credentials';

import { User } from 'reports/models/user';
import { styled } from 'reports/styles/styled-components';
import { useDispatch } from 'react-redux';
import Toaster from 'reports/modules/Toaster';
import { IntegrationOption } from 'reports/modules/settings/team/components/IntegrationCardOverview';

const Logo = styled.img<{ backgroundColor?: string; padding?: string }>`
    background-color: ${(props) => props.backgroundColor};
    height: 40px;
    width: 40px;
    padding: ${(props) => props.padding};
`;

const IntegrationCardContainer = styled(Card)<{ backgroundColor?: string; borderColor?: string }>`
    background-color: ${(props) => props.backgroundColor};
    border-color: ${(props) => props.borderColor};
    display: flex;
    padding: 16px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    max-width: 275px;
    box-shadow: unset;
`;

const IntegrationCardHeader = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`;

const IntegrationCardContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
`;

const IntegrationCardCTA = styled.div`
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
`;

const SwitchContainer = styled.div<{ readOnly?: boolean }>`
    display: flex;
    align-items: center;
    border: ${(props) => (props.readOnly ? '0.92px solid #008e6c4d' : undefined)};
    box-shadow: ${(props) => (props.readOnly ? '0px 0px 11px 0px #00ad8466' : undefined)};
    padding: ${(props) => (props.readOnly ? '18px 15px 18px 15px' : undefined)};
    gap: ${(props) => (props.readOnly ? '9px' : undefined)};
    border-radius: ${(props) => (props.readOnly ? '14.69px' : undefined)};
`;

const IntegrationSwitch = styled(Switch)`
    margin-bottom: 0;

    input:checked + .bp3-control-indicator {
        background-color: #010916 !important;
    }
`;

const IntegrationCardText = styled.p`
    margin: 5px 0px;
    // && gives this specific style higher specificity than the style on parent App.Body that sets p.font-size to 14px.
    && {
        font-size: 12px;
    }
    line-height: normal;
`;

interface IIntegrationCallbacks {
    onCancel?: () => void;
    onDisconnect: () => void;
    onConnect: () => void;
}

interface IIntegrationCardOptions {
    options: IntegrationOption;
    user: User;
    readOnly?: boolean;
}

export type IntegrationCardState = 'Not configured' | 'Enabled' | 'Disabled';

export type IIntegrationCardProps = IIntegrationCallbacks & IIntegrationCardOptions;

const IntegrationCardButton = styled(Button)`
    background-color: #2d72d2 !important;
    margin: 0;
`;

const DisconnectButton = styled(Button)`
    margin: 0;
`;

function IntegrationCard(props: IIntegrationCardOptions) {
    const dispatch = useDispatch();
    const { options, user, readOnly } = props;
    const [cardState, setCardState] = React.useState<IntegrationCardState>(readOnly ? 'Enabled' : 'Not configured');
    const [isCardOpen, setIsCardOpen] = React.useState<boolean>(false);
    const IntegrationCardDialog = options.dialogComponent;

    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    const onDisconnect = async () => {
        setIsLoading(true);
        try {
            await dispatch(externalCredentialsApi.disconnect({ service: options.service }));
        } catch (error) {
            setIsLoading(false);
            Toaster.show({
                message: 'Unknown error.',
                intent: Intent.DANGER,
            });
            return;
        }
        setCardState('Not configured');
        setIsLoading(false);
    };

    const renderButton = () => {
        if (options.service === 'nearmap') {
            if (cardState === 'Not configured') {
                return (
                    <IntegrationCardButton
                        intent={Intent.PRIMARY}
                        text="Configure"
                        onClick={() => setIsCardOpen(true)}
                    />
                );
            }
            if (cardState === 'Enabled') {
                return (
                    <DisconnectButton
                        text="Disconnect"
                        onClick={() => {
                            onDisconnect();
                        }}
                        loading={isLoading}
                    />
                );
            }
        }

        return (
            <IntegrationCardButton
                intent={Intent.PRIMARY}
                text="Configure"
                onClick={() => {
                    if (!readOnly) {
                        setIsCardOpen(true);
                    }
                }}
            />
        );
    };

    const renderTag = () => {
        if (cardState === 'Not configured') {
            return <Tag minimal>{cardState}</Tag>;
        }

        if (options.service === 'nearmap') {
            return (
                <Tag minimal className={classNames(Classes.INTENT_SUCCESS)}>
                    {cardState}
                </Tag>
            );
        }

        return (
            <SwitchContainer readOnly={readOnly}>
                <Tag minimal className={cardState === 'Enabled' ? classNames(Classes.INTENT_SUCCESS) : undefined}>
                    {cardState}
                </Tag>
                <IntegrationSwitch
                    checked={cardState === 'Enabled'}
                    alignIndicator={Alignment.RIGHT}
                    onChange={() => {
                        if (!readOnly) {
                            setCardState(cardState === 'Enabled' ? 'Disabled' : 'Enabled');
                        }
                    }}
                />
            </SwitchContainer>
        );
    };

    if (options.requireTeamAdmin && !user?.team_admin) {
        return null;
    }

    if (options.requireCustomPlan && !user?.team.is_on_custom_plan) {
        return null;
    }

    if (options.service === 'sso' && !user?.hasFeature('enable_sso')) {
        return null;
    }

    return (
        <IntegrationCardContainer
            backgroundColor={cardState !== 'Enabled' ? '#F8F8F8' : undefined}
            borderColor={cardState !== 'Enabled' ? '#F8F8F8' : undefined}
        >
            <IntegrationCardContent>
                <Logo
                    src={options.imgUrl}
                    backgroundColor="#EFEFEF"
                    padding={options.service === 'sso' ? '10px' : undefined}
                />
                <div>
                    <IntegrationCardHeader>
                        <b>{options.title}</b>
                    </IntegrationCardHeader>
                    <IntegrationCardText>{options.shortDescription}</IntegrationCardText>
                </div>
            </IntegrationCardContent>

            <IntegrationCardCTA>
                {renderButton()}
                {renderTag()}
            </IntegrationCardCTA>
            {!readOnly && (
                <IntegrationCardDialog
                    isOpen={isCardOpen}
                    setIsOpen={setIsCardOpen}
                    setCardState={setCardState}
                    cardState={cardState}
                    options={options}
                    user={user}
                />
            )}
        </IntegrationCardContainer>
    );
}

export default IntegrationCard;
