import * as React from 'react';
import { useDispatch } from 'react-redux';
import { actions as routerActions } from 'redux-router5';

import { RouteComponent } from 'reports/routing';
import { ReportLibrary } from 'reports/modules/report/components/ReportLibrary';
import { ReportEditor } from 'reports/modules/report/components/ReportEditor';
import { ReportPreview } from 'reports/modules/report/components/ReportPreview';
import { actions as reportActions } from 'reports/modules/report';
import ProjectFinancialsCalculator from 'reports/modules/project/components/ProjectFinancialsCalculator';

import * as proj from 'reports/models/project';

type Props = {
    project: proj.Project;
};

const Library = ({ project }: Props) => {
    const dispatch = useDispatch();
    const navigateToProjects = () => dispatch(routerActions.navigateTo('app.projects'));
    const navigateToReports = () =>
        dispatch(routerActions.navigateTo('app.reports', { projectId: project.project_id }));

    const navigateToReport = (project, report) => {
        dispatch(reportActions.open(report, true, project.project_id));
    };
    const navigateToReportPreview = (project, report) => {
        dispatch(reportActions.open(report, false, project.project_id));
    };
    return (
        <>
            <ProjectFinancialsCalculator project={project} />
            <div className="sub-container">
                <div className="sub-sidebar" style={{ width: '100%' }}>
                    <RouteComponent name="app.reports" exact={false} nameNot="app.reports.report.edit">
                        <ReportLibrary
                            navigateToProjects={navigateToProjects}
                            navigateToReport={navigateToReport}
                            navigateToReportPreview={navigateToReportPreview}
                            project={project}
                            preview={
                                <RouteComponent name="app.reports.report.preview">
                                    {({ report, data: { printableReady } }) => (
                                        <ReportPreview
                                            navigateToReport={navigateToReport}
                                            navigateToReportPreview={navigateToReportPreview}
                                            navigateToReports={navigateToReports}
                                            printableReady={printableReady}
                                            project={project}
                                            report={report}
                                        />
                                    )}
                                </RouteComponent>
                            }
                        />
                    </RouteComponent>
                    <RouteComponent name="app.reports.report.edit">
                        {({ report, data: { printableReady } }) => (
                            <ReportEditor
                                navigateToReport={navigateToReport}
                                navigateToReportPreview={navigateToReportPreview}
                                printableReady={printableReady}
                                project={project}
                                report={report}
                            />
                        )}
                    </RouteComponent>
                </div>
            </div>
        </>
    );
};

export { Library };
