import { defaultTheme } from '@xstyled/styled-components';
import * as DS from '@aurorasolar/ds';

const tokens = {
    uiActionBar: {
        defaultBgColor: DS.xCSSToken('gray-800'),
    },
    uiButtonContextualNavigation: {
        defaultBgColor: DS.xCSSToken('gray-100'),
        defaultBorderColor: DS.xCSSToken('gray-100'),
        dropdownTextColor: DS.xCSSToken('white'),
    },
    uiButtonPrimary: {
        ...DS.BorealisTheme.colors.uiButtonPrimary,
        defaultBgColor: DS.xCSSToken('blue-500'),
        defaultBorderColor: DS.xCSSToken('blue-500'),
        defaultTextColor: DS.xCSSToken('white'),
        hoverBgColor: DS.xCSSToken('blue-600'),
        hoverBorderColor: DS.xCSSToken('blue-600'),
        hoverTextColor: DS.xCSSToken('white'),
        activeBgColor: DS.xCSSToken('blue-700'),
        activeBorderColor: DS.xCSSToken('blue-700'),
        activeTextColor: DS.xCSSToken('white'),
    },
    uiFocus: '#C294FD', // Temporarily hard-coding this b/c Blueprint dialogs aren't able to retrieve DS colors.
    uiOverrideOption: DS.xCSSToken('orange-400'),
};

// An example of how to extend an existing theme:
// https://github.com/aurorasolar/design-system/blob/main/packages/design-system/src/themes/novo.ts
const theme = {
    ...defaultTheme,
    ...DS.BorealisTheme,
    name: 'HelioScope',
    colors: {
        ...DS.BorealisTheme.colors,
        ...tokens,
    },
};

// @ts-ignore/no-unused-vars
// This allows us to have autocomplete for component props.
export const HelioScopeTheme: DS.ITheme = theme;
