import * as React from 'react';

import * as auth from 'reports/modules/auth';
import { useSelector } from 'react-redux';
import { IAppState } from 'reports/store';
import IntegrationCard from './IntegrationCard';
import { styled } from 'reports/styles/styled-components';
import NearmapCredentialsDialog from 'reports/modules/settings/team/components/NearmapCredentialsDialog';
import SSOCredentialsContainer from 'reports/modules/settings/team/components/SSOCredentialsContainer';

type IntegrationServiceName = 'nearmap' | 'sso'; // Add more service names as needed

export interface IntegrationOption {
    // service is a unique key that is used server side to specify the external integration
    service: string;
    title: string;
    shortDescription: string;
    imgUrl: string;
    dialogInstructions: string | React.ReactNode;
    dialogComponent: React.ComponentType<any>;
    requireTeamAdmin: boolean;
    requireCustomPlan: boolean;
}

export const INTEGRATIONS_LIST_OPTIONS: Record<IntegrationServiceName, IntegrationOption> = {
    nearmap: {
        service: 'nearmap',
        title: 'Nearmap Imagery',
        shortDescription: 'Use an existing Nearmap account to access high quality imagery data in your solar projects',
        imgUrl: require('reports/static/nearmap-icon.png'),
        // props require for the dialog to render
        dialogInstructions: (
            <p>
                Link your existing Nearmap account to access high-resolution imagery in HelioScope.{' '}
                <a href="https://help-center.helioscope.com/hc/en-us/articles/23029761257235">
                    Follow our instructions
                </a>{' '}
                for generating a Nearmap API Key and paste the API Key below.
            </p>
        ),
        dialogComponent: NearmapCredentialsDialog,
        requireTeamAdmin: false,
        requireCustomPlan: false,
    },
    sso: {
        service: 'sso',
        title: 'Single Sign-On',
        shortDescription:
            'Use your Single-sign on account to make logins easier and more secure for users by removing passwords.',
        imgUrl: require('reports/static/sso-key-icon.svg'),
        dialogInstructions: 'Get these details from your SSO provider.',
        dialogComponent: SSOCredentialsContainer,
        requireTeamAdmin: true,
        requireCustomPlan: true,
    },
    // Add more integration options as needed following the same structure
};

const OverviewContainer = styled.div`
    display: flex;
    gap: 30px;
    flex-wrap: wrap;
`;

const IntegrationCardOverview = () => {
    const user = useSelector((state: IAppState) => auth.selectors.getUser(state));

    if (!user) {
        return null;
    }

    return (
        <OverviewContainer>
            {Object.values(INTEGRATIONS_LIST_OPTIONS).map((option) => (
                <IntegrationCard key={option.service} user={user} options={option} />
            ))}
        </OverviewContainer>
    );
};

export { IntegrationCardOverview };
