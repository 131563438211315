import React from 'react';

import { useProjectContext } from 'frontend/projects/use-project';

import { ProjectSharing } from 'reports/modules/project/views/ProjectSharing';

const Sharing = () => {
    const { project } = useProjectContext();
    return <ProjectSharing project={project} />;
};

export { Sharing };
