import React, { useContext, useState } from 'react';
import { generatePath, useLoaderData, useNavigate, useParams } from 'react-router-dom';

import * as DS from '@aurorasolar/ds';
import { Report } from 'reports/models/report';
import { PrintContext, printCurrentView } from 'reports/modules/pdf';

const ProjectReports = () => {
    const reports = useLoaderData() as Report[];
    const params = useParams();
    const projectId = params.projectId || null;
    const navigate = useNavigate();

    return (
        <DS.PageTemplate header={<DS.Text text="h1">Reports</DS.Text>}>
            <DS.Box px="44px" w="50%">
                <DS.ListView
                    columns={[
                        {
                            key: 'name',
                            title: 'Report Name',
                        },
                    ]}
                    getRowActions={(row: { name: string; slug: string }, _) => {
                        const routePath = generatePath('/projects/:projectId/report/:slug', {
                            projectId,
                            slug: row.slug,
                        });
                        // We don't consume canPrint b/c it's defined for this page and not the report - it's always false.
                        const { routerUrlPrefix } = useContext(PrintContext);
                        const [loading, setLoading] = useState<boolean>(false);
                        return [
                            {
                                action: () => navigate(routePath),
                                icon: DS.IconExternalLink,
                            },
                            {
                                loading,
                                action: () =>
                                    printCurrentView({
                                        routePath,
                                        routerUrlPrefix,
                                        setLoading,
                                    }),
                                icon: DS.IconDownload,
                            },
                        ];
                    }}
                    rows={reports.map((report) => ({
                        name: report.name,
                        slug: report.slug,
                    }))}
                />
            </DS.Box>
        </DS.PageTemplate>
    );
};

export { ProjectReports };
