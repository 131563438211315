import React from 'react';

import {
    createSearchParams,
    generatePath,
    URLSearchParamsInit,
    useLocation,
    useNavigate,
    useSearchParams,
} from 'react-router-dom';
import { ReferrerTypes } from 'reports/analytics/ReferrerTypes';
import { Price } from 'reports/models/stripe';

import { ProjectList } from 'reports/modules/project/components/ProjectList';

const Projects = () => {
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const location = useLocation();
    const navigateToProject = (projectId: number) =>
        navigate({
            pathname: generatePath('/projects/:projectId', { projectId: projectId.toString() }),
        });

    const openAddProjectsDialog = () => {
        const newSearchParams = createSearchParams({ ...searchParams, dialog: 'add_projects' } as URLSearchParamsInit);
        navigate({ pathname: location.pathname, search: `?${newSearchParams.toString()}` });
    };
    const redirectToCheckoutFlow = (interval: string, price: Price) => {
        const newSearchParams = createSearchParams({
            ...searchParams,
            interval,
            price: price.id,
            dialog: 'initial',
            referrer: ReferrerTypes.expired_account_dialog,
        } as URLSearchParamsInit);
        navigate({ pathname: '/settings/team/billing', search: `?${newSearchParams.toString()}` });
    };

    return (
        <ProjectList
            navigateToProject={navigateToProject}
            openAddProjectsDialog={openAddProjectsDialog}
            redirectToCheckoutFlow={redirectToCheckoutFlow}
            inlineControls={true}
        />
    );
};

export { Projects };
