import * as React from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { Scenario } from 'reports/models/scenario';
import { DetailsTable, WidgetDetailsTable } from 'reports/modules/report/components/helpers';
import Translations from 'reports/localization/strings';

const weatherDatasetName = (dataset, source = dataset.weather_source) =>
    `${dataset.name}, ${source.name} ` +
    `(${source.source_type}${source.station_class ? ', ' + source.station_class.toUpperCase() : ''})`;

const ScenarioSummaryIntl = ({ scenario, intl }: { scenario: Scenario; intl: IntlShape }) => (
    <tbody>
        <tr>
            <th>
                <FormattedMessage {...Translations.conditions.weather_source} />
            </th>
            <td>{weatherDatasetName(scenario.weather_dataset)}</td>
        </tr>
        <tr>
            <th>
                <FormattedMessage {...Translations.conditions.transposition_model} />
            </th>
            <td>
                {scenario.transposition_model === 'perez'
                    ? intl.formatMessage(Translations.conditions.perez_model)
                    : intl.formatMessage(Translations.conditions.hay_model)}
            </td>
        </tr>
    </tbody>
);
const ScenarioSummary = injectIntl(ScenarioSummaryIntl);

const ScenarioSummaryTable = ({ scenario }: { scenario: Scenario }) => (
    <DetailsTable>
        <ScenarioSummary scenario={scenario} />
    </DetailsTable>
);

interface ScenarioSummaryWidgetTable {
    scenario: Scenario;
    className?: string;
}

export const ScenarioSummaryWidgetTable = ({ scenario, className }: ScenarioSummaryWidgetTable) => (
    <WidgetDetailsTable className={className}>
        <ScenarioSummary scenario={scenario} />
    </WidgetDetailsTable>
);

export default ScenarioSummaryTable;
