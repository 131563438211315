import * as React from 'react';
import { ButtonGroup } from '@blueprintjs/core';
import { Button } from 'reports/components/core/controls';
import { IconNames } from '@blueprintjs/icons';

type Props = {
    canUndo: boolean;
    canRedo: boolean;
    undo: () => Promise<any>;
    redo: () => Promise<any>;
};

const ReportUndoRedoButtons = ({ undo, canUndo, redo, canRedo }: Props) => (
    <ButtonGroup>
        <Button icon={IconNames.UNDO} onClick={undo} disabled={!canUndo} title="Undo (Cmd/Ctrl-Z)" />
        <Button
            icon={IconNames.REDO}
            onClick={redo}
            disabled={!canRedo}
            style={{ marginLeft: 0 }}
            title="Redo (Cmd/Ctrl-Shift-Z)"
        />
    </ButtonGroup>
);
export { ReportUndoRedoButtons };
