import * as React from 'react';
import { useSelector } from 'react-redux';
import { capitalize } from 'lodash';
import { Button } from '@blueprintjs/core';

import * as analytics from 'reports/analytics';
import * as auth from 'reports/modules/auth';
import { IAppState } from 'reports/store';

import FormBareRadioGroup from 'reports/components/forms/inputs/experimental/FormBareRadioGroup';
import Flex from 'reports/components/core/containers/Flex';

import { Price } from 'reports/models/stripe/price';

import { styled } from 'reports/styles/styled-components';

import { Discount } from './Discount';
import { FormBarePlanCard } from './FormBarePlanCard';
import { PlanCard } from './PlanCard';

import { UPSELL_DIALOG_CONTACT_SALES_TEMPLATE } from 'reports/utils/UpsellDialogConstants';

import { formatBillingCurrency, hasPrice, getPrice } from '../';

interface Props {
    basicPrices: Price[];
    proPrices: Price[];
    interval: string;
    price?: Price;
    onUpdate?: ({ interval, price }: { interval: string; price: Price | undefined }) => void;
    isCurrentSelection?: (price: Price) => boolean;
    canUpgrade?: (price: Price) => boolean;
    onPriceChange?: (price: Price) => void;
    referrer: ReferrerType;
    canSelect: boolean;
}

type ReferrerType = 'expired_account_dialog' | 'checkout_dialog';

const INTERVALS: { month: string; year: string } = {
    month: 'monthly',
    year: 'annual',
};

const INTERVAL_PROJECT_AMOUNTS: { [key: string]: number } = {
    month: 10,
    year: 120,
};

const Plans = styled(Flex.Container)`
    justify-content: space-evenly;
    margin: 0px 8px 0px 8px;
    gap: 0px;
`;

const Container = styled.div``;

const RadioGroupContainer = styled.div`
    padding-top: 18px;
    text-align: center;
    width: 100%;
`;

const Footer = styled.div`
    display: block;
    padding: 18px 0px;
    text-align: center;
    width: 100%;

    & > a {
        text-decoration: underline;
    }
`;

const BASIC_FEATURES = [
    '✓ Site Modeling & Designs',
    '✓ Multiple Designs per Project',
    '✓ Bankable Simulations',
    '✓ CAD Export',
    '✓ Single-line Diagram',
    '✓ 50,000 Component Library',
    '✓ Global Weather Coverage',
    '✓ Shade Reports',
    '✓ Basic Support',
];

const PRO_FEATURES = [
    '✓ LIDAR-assisted Modeling',
    '✓ Independent Tilt',
    '✓ Utility Rate Editor',
    '✓ Financial Analysis Tools',
    '✓ Customizable Reports & Sales Proposals',
];

const CUSTOM_FEATURES = [
    '✓ Similar Obstruction Detection',
    '✓ Commercial Modeling Service',
    '✓ Single Axis Trackers design and simulation',
    '✓ Direct API Access',
    '✓ Monthly Analytics Reports',
    '✓ Premium Support',
    '✓ Dedicated Customer Success Manager',
];

const priceAmount = (price: Price) => formatBillingCurrency(price.unit_amount, 0);

const getProFeatures = (hasParcels: boolean) => {
    return hasParcels
        ? [...PRO_FEATURES.slice(0, 1), '✓ Parcel Boundaries (in select regions)', ...PRO_FEATURES.slice(1)]
        : [...PRO_FEATURES];
};

const PlanSelection = ({
    basicPrices,
    proPrices,
    interval,
    onUpdate,
    price,
    canUpgrade,
    isCurrentSelection,
    onPriceChange,
    referrer,
    canSelect,
}: Props) => {
    const user = useSelector((state: IAppState) => auth.selectors.getUser(state));
    const hasParcels = !!user?.hasFeature('enable_parcels');
    const proFeatures = getProFeatures(hasParcels);

    const basicPrice = getPrice(basicPrices, interval);
    const proPrice = getPrice(proPrices, interval);

    const priceIsBasic = hasPrice(basicPrices, price);
    const priceIsPro = hasPrice(proPrices, price);

    const handleSelection = ({ interval }) => {
        if (price && onUpdate) {
            const newPrice = hasPrice(basicPrices, price)
                ? getPrice(basicPrices, interval)
                : getPrice(proPrices, interval);

            onUpdate({ interval, price: newPrice });
        }
    };

    const projectsPerInterval = (interval: string) =>
        `${INTERVAL_PROJECT_AMOUNTS[interval]} projects per license per ${interval}`;

    return (
        <Container>
            <RadioGroupContainer>
                <FormBareRadioGroup
                    inline
                    path="interval"
                    selectedValue={interval}
                    onSelect={(interval: string) => handleSelection({ interval })}
                    items={['month', 'year'].map((priceInterval) => {
                        return {
                            key: priceInterval,
                            label: capitalize(INTERVALS[priceInterval]),
                            radioChildren: priceInterval === 'year' ? <Discount>Save 15%</Discount> : <></>,
                            radioValue: priceInterval,
                        };
                    })}
                />
            </RadioGroupContainer>

            <Plans>
                {basicPrice && (
                    <FormBarePlanCard
                        path="price"
                        cost={{
                            amount: `${priceAmount(basicPrice)}`,
                            description: `per ${INTERVALS[interval]} license`,
                        }}
                        description={
                            <>
                                <div>1 user per license</div>
                                <div>{projectsPerInterval(interval)}</div>
                                <div>1.25 MW (DC) maximum design size</div>
                            </>
                        }
                        features={{
                            title: 'Features',
                            items: BASIC_FEATURES,
                        }}
                        isSelected={priceIsBasic}
                        isCurrentSelection={isCurrentSelection && isCurrentSelection(basicPrice)}
                        onSelect={() => {
                            onPriceChange && onPriceChange(basicPrice);
                            handleSelection({ interval });
                        }}
                        price={basicPrice}
                        subtitle={'For small teams'}
                        title={'Basic Plan'}
                        disabled={canUpgrade ? !canUpgrade(basicPrice) : false}
                        canSelect={canSelect}
                    />
                )}
                {proPrice && (
                    <FormBarePlanCard
                        path="price"
                        cost={{
                            amount: `${priceAmount(proPrice)}`,
                            description: `per ${INTERVALS[interval]} license`,
                        }}
                        description={
                            <>
                                <div>1 user per license</div>
                                <div>{projectsPerInterval(interval)}</div>
                                <div>1.25 MW (DC) maximum design size</div>
                            </>
                        }
                        features={{
                            title: 'Everything in Basic, plus:',
                            items: proFeatures,
                        }}
                        isSelected={priceIsPro}
                        isCurrentSelection={isCurrentSelection && isCurrentSelection(proPrice)}
                        onSelect={() => {
                            onPriceChange && onPriceChange(proPrice);
                            handleSelection({ interval });
                        }}
                        price={proPrice}
                        subtitle={'For growing teams'}
                        title={'Pro Plan'}
                        disabled={canUpgrade ? !canUpgrade(proPrice) : false}
                        canSelect={canSelect}
                    />
                )}
                <PlanCard
                    button={<Button>Talk to Sales</Button>}
                    cost={{ amount: 'Talk to sales' }}
                    description={
                        <>
                            <div>Custom number of licenses and monthly projects</div>
                            <div>Up to 15 MW (DC) maximum design size</div>
                        </>
                    }
                    features={{
                        title: 'Everything in Pro, plus:',
                        items: CUSTOM_FEATURES,
                    }}
                    interactive={canSelect}
                    onClick={() => {
                        analytics.track('checkout.talk_to_sales', { referrer });
                        window.open(UPSELL_DIALOG_CONTACT_SALES_TEMPLATE);
                    }}
                    subtitle={'For scaling companies'}
                    title={'Custom Plan'}
                />
            </Plans>
            <Footer>
                <a
                    target="_blank"
                    href="https://helioscope.aurorasolar.com/pricing/"
                    onClick={() => {
                        analytics.track('checkout.see_all_features', { referrer });
                    }}
                >
                    See all features and benefits
                </a>
            </Footer>
        </Container>
    );
};

export { PlanSelection, getProFeatures, PRO_FEATURES };
