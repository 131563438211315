import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FavoriteStar } from 'reports/components/core/controls';
import { CommonColumns } from 'reports/components/library/helpers';
import { LibraryConsumer } from 'reports/components/library/main/context';
import { ColWidth, SortType } from 'reports/components/core/tables/DataTable';
import { ResourceTable } from 'reports/components/library/main/components/view_profiles';

import * as rep from 'reports/models/report';
import * as auth from 'reports/modules/auth';
import { addPromiseToastsToggle } from 'reports/components/toasts';

type Props = {
    openReport: (report: rep.Report) => void;
};

const ReportsList = ({ openReport }: Props) => {
    const user = useSelector((state) => auth.selectors.getUser(state)!);
    const dispatch = useDispatch();
    const setBookmarked = (report, bookmarked) =>
        dispatch(rep.api.patchTeamSettings({ bookmarked, report_id: report.report_id }));
    const deleteReport = (report) => dispatch(rep.api.delete({ report_id: report.report_id }));
    return (
        <LibraryConsumer>
            {({ updateSearch }) => (
                <ResourceTable<rep.Report>
                    columns={[
                        {
                            colWidth: ColWidth.SMALL,
                            headerText: 'Favorite',
                            renderCell: (report) => (
                                <FavoriteStar
                                    empty={!report.bookmarked()}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        addPromiseToastsToggle(
                                            'favorite',
                                            setBookmarked(report, !report.bookmarked()),
                                            'report',
                                            report.name,
                                            report.bookmarked(),
                                        );
                                    }}
                                />
                            ),
                        },
                        {
                            sort: { name: 'name', type: SortType.ALPHA },
                            colWidth: ColWidth.LARGE,
                            headerText: 'Report Name',
                            renderCell: (report) => (
                                <div style={{ width: '100%', textAlign: 'left' }}>{report.name}</div>
                            ),
                        },
                        CommonColumns.lastModified(),
                        CommonColumns.created(),
                        CommonColumns.creator(),
                        CommonColumns.team(),
                        CommonColumns.publicCol(user),
                        CommonColumns.actionsCol(
                            user,
                            {
                                title: 'Delete Report Template',
                                prompt: `Deleting this report template will remove this template for all users on your team.
                                    Would you like to delete this report?`,
                                resName: 'Report Template',
                            },
                            deleteReport,
                            updateSearch,
                        ),
                    ]}
                    onRowClick={(report) => openReport(report)}
                />
            )}
        </LibraryConsumer>
    );
};

export { ReportsList };
