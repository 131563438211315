import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as proj from 'reports/models/project';
import * as rep from 'reports/models/report';
import * as repCfg from 'reports/models/report/ReportConfiguration';
import * as auth from 'reports/modules/auth';

import { IAppState } from 'reports/store';

import DocumentEditor from '../components/DocumentEditor';
import { ProjectReportViewerControls } from './ProjectReportViewerControls';
import { UpdateReportConfigControls } from '../components/UpdateReportConfigControls';
import { UpgradeToProReportControls } from '../components/UpgradeToProReportControls';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

type Props = {
    inlineControls?: boolean;
    navigateToReport: (project: proj.Project, report: rep.Report) => void;
    navigateToReportConfig: (project: proj.Project, report: rep.Report) => void;
    report: rep.Report;
    project: proj.Project;
    printableReady: Promise<any>;
};

const StyledDocumentEditor = styled(DocumentEditor)<{ inlineControls: boolean }>`
    // 50px is the height of Header. Subtract that to make sure no extra scrollbar is introduced.
    height: ${(props) => (props.inlineControls ? 'calc(100% - 50px)' : '100%')};
`;

const ProjectReportViewer = ({
    inlineControls,
    navigateToReport,
    navigateToReportConfig,
    report,
    project,
    printableReady,
}: Props) => {
    const user = useSelector((state: IAppState) => auth.selectors.getUser(state)!);
    const latestVersion = useSelector((state: IAppState) => rep.versionSelectors.latestVersion(state, report)!);
    const reportCfg = useSelector((state: IAppState) =>
        repCfg.selectors.configForProject(state, report, project.project_id),
    );

    const dispatch = useDispatch();
    const createReportConfig = ({ report_version_id, project_id, configuration_data }) =>
        dispatch(repCfg.api.create({ report_version_id, project_id, configuration_data }));

    const canViewFinancials = user.hasFinancialsAccess();
    const createAndOpenConfigEditor = async () => {
        let cfg = reportCfg;
        if (cfg == null) {
            cfg = await createReportConfig({
                report_version_id: latestVersion.report_version_id,
                project_id: project.project_id,
                configuration_data: { widgets: {} },
            });
        }
        return navigateToReportConfig(project, report);
    };

    let reportControls: React.ReactNode = null;
    if (canViewFinancials) {
        if (reportCfg) {
            reportControls = <UpdateReportConfigControls reportConfig={reportCfg} />;
        }
    } else if (!report.basic_report) {
        reportControls = <UpgradeToProReportControls />;
    }

    return (
        <>
            <ProjectReportViewerControls
                inlineControls={inlineControls!!}
                navigateToReport={navigateToReport}
                report={report}
                project={project}
                reportConfig={reportCfg}
                reportVersion={latestVersion}
                canViewFinancials={canViewFinancials}
                configureBtnOnClick={createAndOpenConfigEditor}
            />
            <StyledDocumentEditor
                inlineControls={inlineControls!!}
                mode="view"
                report={report}
                document={reportCfg ? reportCfg.makeDocument() : latestVersion.document}
                project={project}
                printableReady={printableReady}
                controls={reportControls}
            />
        </>
    );
};

export { ProjectReportViewer };
