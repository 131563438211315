import { Button, Callout, Card } from '@blueprintjs/core';
import * as DS from '@aurorasolar/ds';
import React from 'react';

interface SSOCopyCardProps {
    title: string;
    titleIcon: React.ReactNode | null;
    text: string;
    style?: React.CSSProperties;
}

const SSOCopyCard = ({ title, titleIcon = null, text, style = {} }: SSOCopyCardProps) => {
    const onCopyText = (text: string) => {
        navigator.clipboard.writeText(text);
    };

    return (
        <Card style={{ padding: '10px', marginBottom: '15px', ...style }}>
            <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center', gap: '8px' }}>
                {titleIcon}
                <DS.Text fontSize="body14">{title}</DS.Text>
            </div>
            {text && (
                <Callout
                    style={{
                        flexDirection: 'row',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '10px',
                        gap: '8px',
                    }}
                >
                    <DS.Text
                        fontSize="body14"
                        style={{
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}
                    >
                        {text}
                    </DS.Text>
                    <Button minimal={true} style={{ minHeight: '16px', minWidth: '16px', padding: '0' }}>
                        <DS.IconCopy onClick={() => onCopyText(text)} size="16px" />
                    </Button>
                </Callout>
            )}
        </Card>
    );
};

export default SSOCopyCard;
