import React from 'react';
import { NavigateFunction, useLocation, useNavigate } from 'react-router';
import { Button, ButtonGroup, Card, Flex } from '@aurorasolar/ds';
import { addDefaultHeader, HEADERS } from 'reports/modules/request';
import * as auth from 'reports/modules/auth';
import { useDispatch, useSelector } from 'react-redux';

const defaultErrorSvg = require('frontend/media/error-default.svg');
const forbiddenErrorSvg = require('frontend/media/error-403.svg');

const ErrorPage = ({ status }: { status: number | null }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { state } = useLocation();
    const { path, ownerEmail } = state;
    const is403Error = status === 403;
    const user = useSelector((state) => auth.selectors.getUser(state));

    const retryWithAsUser = (email: string) => {
        if (user) {
            user._masquerading_admin = true;
            user._masquerading_admin_email = user.email;
            user.email = email;
            dispatch(
                auth.api.login.done({
                    result: user,
                    params: {},
                } as any),
            );
        }
        navigate(path);
    };

    const retryWithOverride = () => {
        addDefaultHeader(HEADERS.overridePermissions, true);
        navigate(path);
    };

    return (
        <Flex alignItems="center" justifyContent="center" flexDirection="column" minHeight="100%" rowGap={0}>
            {is403Error ? (
                <ErrorCard
                    flexStyle="flex-start"
                    imgPath={forbiddenErrorSvg}
                    navigate={navigate}
                    title="Access denied"
                    retryWithAsUser={retryWithAsUser}
                    retryWithOverride={retryWithOverride}
                    ownerEmail={ownerEmail}
                    showAdminButtons={user!.is_admin}
                />
            ) : (
                <ErrorCard
                    flexStyle="flex-end"
                    imgPath={defaultErrorSvg}
                    navigate={navigate}
                    title="We've drifted off course"
                    showAdminButtons={false}
                />
            )}
        </Flex>
    );
};

const ErrorCard = ({
    flexStyle,
    imgPath,
    navigate,
    ownerEmail,
    retryWithAsUser,
    retryWithOverride,
    showAdminButtons,
    title,
}: {
    flexStyle: string;
    imgPath: string;
    navigate: NavigateFunction;
    ownerEmail?: string;
    retryWithAsUser?: (email: string) => void;
    retryWithOverride?: () => void;
    showAdminButtons: boolean;
    title: string;
}) => {
    const HSAdminButtons = () =>
        ownerEmail && retryWithAsUser ? (
            <Button
                variant="primary"
                justifyContent="normal"
                textOverflow="ellipsis"
                onClick={() => retryWithAsUser(ownerEmail)}
                flexShrink={1}
            >
                {`Impersonate ${ownerEmail}`}
            </Button>
        ) : (
            <Button variant="primary" onClick={retryWithOverride}>
                Override
            </Button>
        );
    return (
        <>
            <Flex w={350} minH={216} flexDirection="row" bg="gray-200" alignItems={flexStyle} justifyContent="center">
                <img src={imgPath}></img>
            </Flex>
            <Card maxW={350} bg="gray-100" flexGrow={0} border="none" padding="none">
                <h2>{title}</h2>
                <div>Something went wrong and we're not able to find what you're looking for.</div>
                <div>
                    <a href="mailto:support@helioscope.com">Contact support</a> if the problem persists.
                </div>
                <ButtonGroup>
                    <Button variant="primary" onClick={() => navigate('/')}>
                        Home
                    </Button>
                    {showAdminButtons && <HSAdminButtons />}
                </ButtonGroup>
            </Card>
        </>
    );
};

export { ErrorPage };
