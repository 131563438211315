/* tslint:disable:variable-name function-name */
import { schema } from './schema';
import { BaseClass, ReduxEndpoint } from 'reports/utils/api';

export interface IPostCredentialsForm {
    service: string;
    api_key: string;
}

class ExternalCredentials extends BaseClass {
    external_credentials_id: number;
    api_key: string;
    service: string;

    toString() {
        return `${this.external_credentials_id}: ${this.service}`;
    }
}

const schemaObj = schema.addObject(ExternalCredentials, 'external_credentials', {
    idName: 'external_credentials_id',
});

const endpoint = ReduxEndpoint.fromSchema('/api/external_credentials/', schemaObj);

const api = {
    index: endpoint.index(),
    create: endpoint.post<IPostCredentialsForm>(),
    get: endpoint.get<{ service: string }>('{service}'),
    disconnect: endpoint.put<{ service: string }>('disconnect/{service}/'),
};

const selectors = {
    byId: schemaObj.selectById,
    byObject: schemaObj.selectByObject,
    all: schemaObj.selectAll,
};

export { ExternalCredentials, api, schemaObj, selectors };
