import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import Flex from 'reports/components/core/containers/Flex';
import Section from 'reports/components/helpers/Section';
import CardSection from 'reports/components/core/containers/CardSection';

import * as proj from 'reports/models/project';
import { actions } from 'reports/modules/financials/state';
import { actions as projActions, selectors as projSelectors } from 'reports/modules/project';

import FinancialInputsEditor from 'reports/modules/financials/components/FinancialConfigurationMini';

import FinancialConfigSelect, { isFinConfig, FinConfigs } from './FinancialConfigSelect';

interface Props {
    navigateToFinancials: (project: proj.Project, primaryFinancialConfiguration) => void;
    project: proj.Project;
}

const FinancialInputs = ({ project, navigateToFinancials }: Props) => {
    const dispatch = useDispatch();
    const createFinancialConfig = (template) => dispatch(actions.createConfiguration(template, project));
    const setFinancialConfig = (config) => dispatch(projActions.setPrimaryProjectFinancialTemplate(project, config));
    const primaryFinConfig = useSelector((state) => projSelectors.primaryProjectFinancialTemplate(state, { project }));

    const selectOrCreateFinConfig = async (model: FinConfigs) => {
        const config = isFinConfig(model) ? model : await createFinancialConfig(model);
        setFinancialConfig(config);
    };

    return (
        <Flex.ContainerV>
            <CardSection
                title="Financial Inputs"
                contextEl={
                    <FinancialConfigSelect
                        project={project}
                        onItemSelect={selectOrCreateFinConfig}
                        activeConfig={primaryFinConfig}
                        style={{ maxWidth: 400 }}
                    />
                }
            >
                {primaryFinConfig ? (
                    <>
                        <FinancialInputsEditor config={primaryFinConfig} />
                        <Section.Footer>
                            <Button
                                text="View Detailed Configuration"
                                icon={IconNames.SHARE}
                                onClick={() => navigateToFinancials(project, primaryFinConfig)}
                            />
                        </Section.Footer>
                    </>
                ) : null}
            </CardSection>
        </Flex.ContainerV>
    );
};

export default FinancialInputs;
