import React, { useEffect } from 'react';
import { Outlet, useLoaderData, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { installProjectListeners, removeProjectListeners } from 'reports/modules/project/listeners';
import { createRoutePath } from 'reports/routing/common';

import { Project, selectors as projectSelectors } from 'reports/models/project';
import { IAppState } from 'reports/store';

const ReportsBase = () => {
    // We are using the presence of a default id here to suggest that we couldn't
    // find a project for reports initially and that we need to redirect
    // to the correct route using a default project id.
    const {
        defaultProjectId,
        printableReady,
        project: projectToSelect,
    } = useLoaderData() as {
        defaultProjectId?: string;
        printableReady?: Promise<void>;
        project?: Project;
    };

    const project = projectToSelect
        ? useSelector((state) =>
              projectSelectors.byObject(state as IAppState, {
                  project_id: projectToSelect.project_id,
              }),
          )
        : undefined;

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (project) {
            installProjectListeners(project.project_id, dispatch);
        }
        return () => (project ? removeProjectListeners(project.project_id) : undefined);
    }, [project?.project_id]);

    useEffect(() => {
        if (defaultProjectId) {
            navigate(createRoutePath('/reports', {}, '', { projectId: defaultProjectId }));
        }
        return () => {};
    }, [defaultProjectId]);

    return <Outlet context={{ project, printableReady }} />;
};

export { ReportsBase };
