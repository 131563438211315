import * as React from 'react';

import { Classes, Icon, InputGroup } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { Flex } from 'reports/components/core/containers';
import { Text } from '@aurorasolar/ds';
import { PrimaryButton, PrimaryIntent } from 'reports/components/core/controls';
import { CheckboxInput, Form, FormInput } from 'reports/components/forms';
import { FormErrorCallout } from 'reports/components/helpers/errors';
import { DialogState } from './LoginDialog';

import { ILoginForm } from 'reports/models/user';
import { StyledLink } from './common';

interface LoginFormProps {
    onSubmit: (formData: ILoginForm) => any;
    errorMessage: string | null;
    loading: boolean;
    dialogState: DialogState;
    setDialogState: (dialogState: DialogState) => void;
}

interface PasswordFooterProps {
    setDialogState: (dialogState: DialogState) => void;
}

const EmailFooter = () => (
    <Flex.Container style={{ justifyContent: 'space-between' }}>
        <div>
            <StyledLink routeName="app.signup">Sign up</StyledLink>
        </div>
    </Flex.Container>
);

const PasswordFooter = ({ setDialogState }: PasswordFooterProps) => (
    <>
        <CheckboxInput
            path="remember_me"
            defaultValue={false}
            label="Keep me signed in"
            style={{ marginBottom: '0px', width: '100%' }}
        />
        <Flex.Container style={{ justifyContent: 'space-between', flexDirection: 'row' }}>
            <div>
                <Text
                    color={'#f80 !important'}
                    onClick={() => {
                        setDialogState('email');
                    }}
                    style={{ cursor: 'pointer' }}
                >
                    Back to email
                </Text>
            </div>
            <div>
                <StyledLink routeName="app.forgot-password">Forgot password?</StyledLink>
            </div>
        </Flex.Container>
    </>
);

export const LoginForm = ({ onSubmit, errorMessage, loading, dialogState, setDialogState }: LoginFormProps) => (
    <Form onSubmit={onSubmit} keepStateOnSubmit>
        <div className={Classes.DIALOG_BODY}>
            {errorMessage && <FormErrorCallout style={{ marginBottom: '10px' }} errorMsg={errorMessage} />}
            <FormInput
                path="email"
                placeholder="Email Address"
                defaultValue=""
                inputComponent={InputGroup}
                inputProps={{
                    leftIcon: dialogState === 'email' ? <Icon icon={IconNames.ENVELOPE} /> : null,
                    type: 'email',
                    large: true,
                    autoComplete: 'username',
                    autoCorrect: 'off',
                    autoCapitalize: 'off',
                    spellCheck: 'false',
                    style: dialogState === 'password' ? { display: 'none' } : {},
                }}
            />
            <FormInput
                path="password"
                placeholder="Password"
                defaultValue=""
                inputComponent={InputGroup}
                inputProps={{
                    leftIcon: dialogState === 'password' ? <Icon icon={IconNames.LOCK} /> : null,
                    type: 'password',
                    large: true,
                    autoComplete: 'current-password',
                    autoCorrect: 'off',
                    autoCapitalize: 'off',
                    spellCheck: 'false',
                    style: dialogState === 'email' ? { display: 'none' } : {},
                }}
            />

            <PrimaryButton
                large
                type="submit"
                text={dialogState === 'email' ? 'Next' : 'Sign in'}
                loading={loading}
                intent={PrimaryIntent.CREATE}
                style={{ marginBottom: '0px', width: '100%', margin: '0px 0px' }}
            />
        </div>
        <div className={Classes.DIALOG_FOOTER}>
            {dialogState === 'email' && <EmailFooter />}
            {dialogState === 'password' && <PasswordFooter setDialogState={setDialogState} />}
        </div>
    </Form>
);

export default LoginForm;
