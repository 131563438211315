import * as DS from '@aurorasolar/ds';
import React from 'react';

import styled from 'styled-components';
import IntegrationCard from './IntegrationCard';
import { INTEGRATIONS_LIST_OPTIONS } from './IntegrationCardOverview';
import { User } from 'reports/models/user';

const Logo = styled.img`
    height: auto;
    width: 225px;
    min-width: 225px;
`;
const logo = require('reports/static/helioscope-black.png');

interface SSOTestResultProps {
    user: User;
}

const SSOTestResult = ({ user }: SSOTestResultProps) => {
    return (
        <DS.Grid position="fixed" top="0" bottom="0" left="0" right="0" flexDirection={'column'}>
            <DS.GridItem padding="64px" size="half" backgroundColor="uiBg" justifyContent={'space-between'}>
                <DS.Box alignSelf={'flex-start'}>
                    <Logo src={logo} />
                </DS.Box>
                <DS.Flex marginTop="auto" marginBottom="auto" flexDirection={'column'} maxWidth="429px">
                    <DS.IconCheckCircleFill color="green-400" size="32px" />
                    <DS.Text text="h1">SSO is connected</DS.Text>
                    <DS.Text text="body14">
                        Your SSO test was successful. To enable it for your team, go to the integrations page and flip
                        the toggle under Single-sign on.
                    </DS.Text>
                </DS.Flex>
            </DS.GridItem>
            <DS.GridItem size="half" alignItems="center" justifyContent="center" backgroundColor="uiBgAlternate">
                <IntegrationCard options={INTEGRATIONS_LIST_OPTIONS.sso} readOnly={true} user={user} />
            </DS.GridItem>
        </DS.Grid>
    );
};

export default SSOTestResult;
