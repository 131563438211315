import * as React from 'react';

import { Project } from 'reports/models/project';
import { Report, ReportVersion, ReportConfiguration } from 'reports/models/report';

import { ContextBarControls } from 'reports/components/ContextBar';
import { EditButton } from 'reports/components/core/controls';
import { PrintButton } from 'reports/modules/pdf';

import { EditReportTemplateButton } from '../components/EditReportTemplateButton';
import { ViewReportTemplateButton } from '../components/ViewReportTemplateButton';
import { HeaderContainer } from 'reports/modules/report/components/ReportEditor';

type Props = {
    canViewFinancials: boolean;
    configureBtnOnClick: () => Promise<any>;
    inlineControls?: boolean;
    navigateToReport: (project: Project, report: Report) => void;
    project: Project;
    report: Report;
    reportConfig: ReportConfiguration | null;
    reportVersion: ReportVersion | null;
};

const ProjectReportViewerControls = ({
    inlineControls,
    canViewFinancials,
    configureBtnOnClick,
    navigateToReport,
    project,
    report,
    reportConfig,
    reportVersion,
}: Props) => {
    const controls = (
        <>
            <PrintButton />
            {canViewFinancials ? (
                <>
                    {reportConfig || reportVersion?.document.configurable ? (
                        <>
                            <ViewReportTemplateButton report={report} project={project} />
                            <EditButton text="Configure" onClick={configureBtnOnClick} />
                        </>
                    ) : (
                        <EditReportTemplateButton
                            navigateToReport={navigateToReport}
                            report={report}
                            project={project}
                        />
                    )}
                </>
            ) : (
                <EditReportTemplateButton
                    navigateToReport={navigateToReport}
                    report={report}
                    project={project}
                    upsellDisabled={false}
                />
            )}
        </>
    );
    return inlineControls ? (
        <HeaderContainer>
            <div>{report.name}</div>
            <div style={{ marginLeft: 'auto' }}>{controls}</div>
        </HeaderContainer>
    ) : (
        <ContextBarControls>{controls}</ContextBarControls>
    );
};

export { ProjectReportViewerControls };
