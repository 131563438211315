import * as React from 'react';

import { ButtonProps, Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { EditButton } from 'reports/components/core/controls';
import { promptModalBoolean } from 'reports/components/dialog';

import * as proj from 'reports/models/project';
import * as rep from 'reports/models/report';

import UpsellDialogLauncher from 'reports/components/dialog/components/UpsellDialogLauncher';
import { UPSELL_DIALOG_CONTENT } from 'reports/utils/UpsellDialogConstants';

type Props = {
    navigateToReport: (project: proj.Project, report: rep.Report) => void;
    project: proj.Project;
    report: rep.Report;
    upsellDisabled?: boolean;
};

const EditReportTemplateButton = ({ navigateToReport, project, report, upsellDisabled = true }: Props) => {
    const handleEditReportTemplate = async () => {
        const shouldEditTemplate = await promptModalBoolean({
            title: 'Edit this Report Template?',
            prompt: (
                <p>
                    Editing a report template modifies the report being used by multiple projects. If you're changing
                    only for this project, make sure to use <b>Save Report As</b>. Would you still like to continue?
                </p>
            ),
            cancellable: true,
            noLabel: 'No',
            yesLabel: 'Yes',
            dialogProps: { icon: IconNames.WARNING_SIGN },
        });

        if (shouldEditTemplate) {
            navigateToReport(project, report);
        }
    };

    const getButtonProps = (openDialog: () => void): ButtonProps => {
        let buttonProps: ButtonProps = {
            icon: IconNames.EDIT,
            intent: Intent.SUCCESS,
            onClick: () => handleEditReportTemplate(),
        };

        if (!upsellDisabled) {
            buttonProps = {
                ...buttonProps,
                icon: null,
                rightIcon: IconNames.OFFLINE,
                intent: Intent.PRIMARY,
                onClick: () => openDialog(),
            };
        }

        return buttonProps;
    };

    return (
        <UpsellDialogLauncher
            content={UPSELL_DIALOG_CONTENT.REPORTS_AND_PROPOSALS}
            referrer="edit_report_template_button"
            disabled={!!upsellDisabled}
        >
            {({ openDialog }) => <EditButton text="Edit Report Template" {...getButtonProps(openDialog)} />}
        </UpsellDialogLauncher>
    );
};

export { EditReportTemplateButton };
