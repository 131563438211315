import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { ProjectContext, useProject } from 'frontend/projects/use-project';

import { installProjectListeners, removeProjectListeners } from 'reports/modules/project/listeners';

const ProjectBase = () => {
    const { project, printableReady } = useProject();
    const dispatch = useDispatch();

    if (!project) {
        throw new Response('Not Found', { status: 404 });
    }

    useEffect(() => {
        installProjectListeners(project.project_id, dispatch);
        return () => removeProjectListeners(project.project_id);
    }, []);

    return <Outlet context={{ printableReady, project } satisfies ProjectContext} />;
};

export { ProjectBase };
