import { useDispatch } from 'react-redux';
import React from 'react';

import { Intent } from '@blueprintjs/core';

import { api as SSOProviderApi, SSOProvider } from 'reports/models/sso_provider';

import { Toaster } from 'reports/modules/Toaster';

import SSOCredentialsDialog, { clientSecretPlaceholder } from './SSOCredentialsDialog';

import { User } from 'reports/models/user';
import SSOCredentialsConfirmDialog from 'reports/modules/settings/team/components/SSOCredentialsConfirmDialog';
import { IntegrationCardState } from './IntegrationCard';
import { IntegrationOption } from 'reports/modules/settings/team/components/IntegrationCardOverview';
import SSOCredentialsEnabledSwitchConfirmDialog from 'reports/modules/settings/team/components/SSOCredentialsEnabledSwitchConfirmDialog';

interface SSOCredentialsContainerProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    cardState: IntegrationCardState;
    setCardState: (state: IntegrationCardState) => void;
    options: IntegrationOption;
    user: User;
}

const SSOCredentialsContainer = ({
    isOpen,
    setIsOpen,
    cardState,
    setCardState: setCardState,
    options,
    user,
}: SSOCredentialsContainerProps) => {
    const dispatch = useDispatch();
    const [ssoProvider, setSSOProvider] = React.useState<SSOProvider>(new SSOProvider({}));
    const [loadingSSOProvider, setLoadingSSOProvider] = React.useState<boolean>(false);
    const [confirmDialogOpen, setConfirmDialogOpen] = React.useState<boolean>(false);
    const [loadingDeleteSSOProvider, setLoadingDeleteSSOProvider] = React.useState<boolean>(false);

    const [openEnabledSwitchConfirmDialog, setOpenEnabledSwitchConfirmDialog] = React.useState<boolean>(false);

    React.useEffect(() => {
        const fetchData = async () => {
            setLoadingSSOProvider(true);
            try {
                const ssoProvider = await dispatch(SSOProviderApi.get({ teamId: user.team_id }));
                setSSOProvider(ssoProvider);
                if (ssoProvider.enabled) {
                    setCardState('Enabled');
                } else {
                    setCardState('Disabled');
                }
            } catch {
                setSSOProvider(new SSOProvider({}));
                setCardState('Not configured');
            }
            setLoadingSSOProvider(false);
        };

        fetchData();
    }, []);

    React.useEffect(() => {
        const switchEnabledSSO = async () => {
            const isEnabled = cardState === 'Enabled';
            if (ssoProvider?.sso_provider_id && ssoProvider.enabled !== isEnabled) {
                setOpenEnabledSwitchConfirmDialog(true);
            }
        };
        switchEnabledSSO();
    }, [cardState]);

    const handleClose = () => {
        setIsOpen(false);
    };

    const onSubmit = async (formData: SSOProvider) => {
        if (formData.client_secret === clientSecretPlaceholder) {
            delete formData.client_secret;
        }

        const ssoProvider = await dispatch(
            formData.sso_provider_id ? SSOProviderApi.save(formData) : SSOProviderApi.create(formData),
        );
        setSSOProvider(ssoProvider as SSOProvider);
        setCardState(ssoProvider.enabled ? 'Enabled' : 'Disabled');
        Toaster.show({
            intent: Intent.SUCCESS,
            message: `Successfully saved SSO Provider`,
            timeout: 5000,
        });
    };

    const onConfirm = async () => {
        setLoadingDeleteSSOProvider(true);
        try {
            await dispatch(SSOProviderApi.delete({ sso_provider_id: ssoProvider.sso_provider_id }));
            setCardState('Not configured');
        } catch {
            Toaster.show({
                intent: Intent.DANGER,
                message: `Failed to remove Single Sign-On.`,
                timeout: 5000,
            });
            setLoadingDeleteSSOProvider(false);
            return;
        }
        setSSOProvider(new SSOProvider({}));
        setConfirmDialogOpen(false);
        handleClose();
        Toaster.show({
            intent: Intent.SUCCESS,
            message: `Single Sign-On removed.`,
            timeout: 5000,
        });
        setLoadingDeleteSSOProvider(false);
    };

    const onConfirmClose = () => {
        setConfirmDialogOpen(false);
        setIsOpen(true);
    };

    const onDeleteButtonCLicked = () => {
        setConfirmDialogOpen(true);
        setIsOpen(false);
    };

    const onEnabledSwitchConfirmDialogClose = () => {
        setCardState(cardState === 'Enabled' ? 'Disabled' : 'Enabled');
        setOpenEnabledSwitchConfirmDialog(false);
    };

    const onEnabledSwitchConfirmDialogConfirm = async () => {
        const isEnabled = cardState === 'Enabled';
        try {
            const updatedSSOProvider = await dispatch(
                SSOProviderApi.save({ sso_provider_id: ssoProvider.sso_provider_id, enabled: isEnabled }),
            );
            setSSOProvider(updatedSSOProvider as SSOProvider);
            setOpenEnabledSwitchConfirmDialog(false);
        } catch (error) {
            setCardState(cardState === 'Enabled' ? 'Disabled' : 'Enabled');
            Toaster.show({
                message: 'Unknown error.',
                intent: Intent.DANGER,
            });
            return;
        }
    };

    return (
        <>
            <SSOCredentialsDialog
                handleClose={handleClose}
                isOpen={isOpen}
                onSubmit={onSubmit}
                options={options}
                ssoProvider={ssoProvider}
                loadingSSOProvider={loadingSSOProvider}
                onDeleteButtonClicked={onDeleteButtonCLicked}
            />
            <SSOCredentialsConfirmDialog
                isOpen={confirmDialogOpen}
                onConfirm={onConfirm}
                onClose={() => onConfirmClose()}
                loading={loadingDeleteSSOProvider}
            />
            <SSOCredentialsEnabledSwitchConfirmDialog
                ssoProvider={ssoProvider}
                isOpen={openEnabledSwitchConfirmDialog}
                onClose={onEnabledSwitchConfirmDialogClose}
                onConfirm={onEnabledSwitchConfirmDialogConfirm}
            />
        </>
    );
};

export default SSOCredentialsContainer;
