import { sortBy } from 'lodash';
import * as React from 'react';
import { connect } from 'react-redux';

import { Card, Button, H2, Classes, FormGroup } from '@blueprintjs/core';

import * as authToken from 'reports/models/auth_token';

import { styled } from 'reports/styles/styled-components';

const FormControlFlexbox = styled.div`
    display: flex;
    gap: 5px;
`;

interface IAuthTokenCardProps {
    onRevoke: () => void;
    token: authToken.AuthToken;
}

const AuthTokenCard: React.SFC<IAuthTokenCardProps> = (props) => {
    return (
        <Card>
            <div>Name: {props.token.client_name}</div>
            <div>Token ID: {props.token.token_id}</div>
            <div>Created at: {props.token.issued_at.format('lll')}</div>
            <Button text="Revoke" icon="delete" onClick={props.onRevoke} />
        </Card>
    );
};

interface ICreateTokenFormProps {
    onSubmit: (tokenId: string) => void;
}

class CreateTokenForm extends React.PureComponent<ICreateTokenFormProps> {
    state = {
        clientName: '',
    };

    onClientNameChange = (event) => this.setState({ clientName: event.target.value });

    onSubmit = () => {
        this.props.onSubmit(this.state.clientName);
        this.setState({ clientName: '' });
    };

    render() {
        return (
            <form>
                <FormGroup inline label="Name:">
                    <FormControlFlexbox>
                        <input
                            className={Classes.INPUT}
                            type="text"
                            value={this.state.clientName}
                            onChange={this.onClientNameChange}
                        />
                        <Button text="Create" type="button" onClick={this.onSubmit} />
                    </FormControlFlexbox>
                </FormGroup>
            </form>
        );
    }
}

interface IAuthTokenListProps {
    revokeToken: (token: authToken.AuthToken) => Promise<void>;
    createToken: (clientName: string) => Promise<authToken.AuthToken>;
    tokens: authToken.AuthToken[];
}

interface IAuthTokenListState {
    errorMessage: string;
}

class AuthTokenList extends React.PureComponent<IAuthTokenListProps, IAuthTokenListState> {
    state: IAuthTokenListState = {
        errorMessage: '',
    };

    createToken = (clientName: string) => {
        this.setState({ errorMessage: '' });
        this.props.createToken(clientName).catch((error) => {
            if (error.response && error.response.body && error.response.body.client_name) {
                this.setState({
                    errorMessage: 'Client name: ' + error.response.body.client_name.join('; '),
                });
            } else {
                this.setState({ errorMessage: 'An error occurred' });
            }
        });
    };

    revokeToken = (token: authToken.AuthToken) => {
        this.setState({ errorMessage: '' });
        this.props.revokeToken(token).catch(() => {
            this.setState({
                errorMessage: 'Error occurred trying to revoke token',
            });
        });
    };

    render() {
        const tokens = sortBy(this.props.tokens, ['issued_at']).reverse();
        return (
            <div>
                <H2>Auth Tokens</H2>
                <CreateTokenForm onSubmit={this.createToken} />
                <div>{this.state.errorMessage}</div>
                {tokens.map((token) => (
                    <AuthTokenCard token={token} key={token.token_id} onRevoke={this.revokeToken.bind(null, token)} />
                ))}
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        tokens: authToken.selectors.all(state),
    };
}

function mapDispatchToProps(dispatch) {
    return {
        createToken: (clientName: string) => dispatch(authToken.api.create({ client_name: clientName })),
        revokeToken: (token: authToken.AuthToken) => dispatch(authToken.api.delete({ token_id: token.token_id })),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthTokenList);
