import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actions as routerActions } from 'redux-router5';

import { Button } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { Card } from 'reports/components/core/containers';
import { EditableTitleSubHeader } from 'reports/components/helpers/common';
import CardSection from 'reports/components/core/containers/CardSection';

import { Design } from 'reports/models/design';
import { Project, saver as projectSaver } from 'reports/models/project';
import { selectors } from 'reports/modules/project';

import * as auth from 'reports/modules/auth';

import ConsumptionEditor from 'reports/modules/consumption/components/ConsumptionEditor';
import ConsumptionEnergyChart from 'reports/modules/consumption/components/ConsumptionEnergyChart';
import FinancialMetricsTable from 'reports/modules/financials/components/FinancialMetrics';
import IncentiveEditor from 'reports/modules/financials/components/ProjectIncentiveEditor';
import UtilityRateEditor from 'reports/modules/financials/components/ProjectRateEditor';
import LifetimeProduction from 'reports/modules/financials/components/LifetimeProduction';

import FinancialInputs from './FinancialInputs';
import ProjectDetailTable from './ProjectDetailTable';
import ProjectExportTable from './ProjectExportTable';
import ScenarioDropdown from './ScenarioDropdown';
import ScenarioSummary from './ScenarioSummary';
import SystemDesign from './SystemDesign';
import EditProjectDetailsLauncher from './EditProjectDetailsLauncher';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

interface Props {
    project: Project;
    editDesign: (project: Project) => void;
    navigateToDesigner: (design: Design) => void;
    navigateToFinancials: (project: Project, primaryFinancialConfiguration) => void;
}

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
`;

// Injects a bunch of router5 based route logic into ProjectOverview.
// This should only be used in beta and not in foxfire.
const ProjectOverviewBare = ({ project }: { project: Project }) => {
    const dispatch = useDispatch();

    const editDesign = (project) => {
        return dispatch(
            routerActions.navigateTo('app.projects.project.designer', {
                projectId: project.project_id,
                designId: project.primary_design_id,
                subpath: 'field_segments',
            }),
        );
    };

    const navigateToDesigner = (design) =>
        dispatch(
            routerActions.navigateTo('app.projects.project.designer', {
                projectId: design.project_id,
                designId: design.design_id,
                subpath: 'field_segments',
            }),
        );

    const navigateToFinancials = (project: Project, primaryFinConfig) =>
        dispatch(
            routerActions.navigateTo('app.projects.project.financial-configurations.financial-configuration', {
                projectId: project.project_id,
                finConfigId: primaryFinConfig.project_financial_template_id,
            }),
        );

    return (
        <ProjectOverview
            editDesign={editDesign}
            navigateToDesigner={navigateToDesigner}
            navigateToFinancials={navigateToFinancials}
            project={project}
        />
    );
};

const ProjectOverview = (props: Props) => {
    const dispatch = useDispatch();

    // we know we have a selected project because of our router loader
    const project = props.project!;

    const updateProject = (data) => dispatch(projectSaver.get(project).patch(data));

    const user = useSelector((state) => auth.selectors.getUser(state)!);
    const primaryDesign = useSelector((state) => selectors.primaryDesign(state, props));
    const primaryScenario = useSelector((state) => selectors.primaryScenario(state, props));
    const primarySimulation = useSelector((state) => selectors.primarySimulation(state, props));
    const canViewFin = user.hasFinancialsAccess();

    const inner = (
        <GridContainer>
            <Card>
                <CardSection
                    title="Project Details"
                    contextEl={project.is_admin && <EditProjectDetailsLauncher project={project} user={user} />}
                >
                    <ProjectDetailTable project={project} />
                </CardSection>
            </Card>
            <Card>
                <SystemDesign
                    project={project}
                    design={primaryDesign}
                    editDesign={props.editDesign}
                    navigateToDesigner={props.navigateToDesigner}
                />
            </Card>
            {canViewFin && (
                <Card>
                    <UtilityRateEditor project={project} />
                    <ConsumptionEditor project={project} />
                    <IncentiveEditor project={project} />
                </Card>
            )}
            <Card>
                <CardSection
                    title="Condition Set"
                    contextEl={
                        <ScenarioDropdown project={project}>
                            <Button
                                disabled={project.scenarios.length === 0}
                                rightIcon={IconNames.CARET_DOWN}
                                text={primaryScenario != null ? primaryScenario.description : 'No Scenario'}
                            />
                        </ScenarioDropdown>
                    }
                >
                    <div style={{ margin: '4px 0px' }}>
                        {primaryScenario != null ? <ScenarioSummary scenario={primaryScenario} /> : null}
                    </div>
                    {primaryDesign && primarySimulation && primarySimulation.complete() ? (
                        <ConsumptionEnergyChart project={project} />
                    ) : null}
                </CardSection>
            </Card>
            {canViewFin && (
                <Card>
                    <FinancialInputs navigateToFinancials={props.navigateToFinancials} project={project} />
                </Card>
            )}
            {canViewFin && (
                <Card>
                    <CardSection title="Financial Metrics">
                        {primaryDesign != null && primarySimulation != null ? (
                            <LifetimeProduction project={project} />
                        ) : null}
                        <FinancialMetricsTable project={project} />
                    </CardSection>
                </Card>
            )}
            <Card>
                <CardSection title="Project Files Exporter">
                    <ProjectExportTable project={project} />
                </CardSection>
            </Card>
        </GridContainer>
    );

    const outer = (
        <>
            <div className="content-header">
                <EditableTitleSubHeader value={project.name} updateFn={(value) => updateProject({ name: value })} />
            </div>
            <div className="sub-content-inner" style={{ padding: '8px' }}>
                {inner}
            </div>
            <div className="sub-content-footer" />
        </>
    );

    return (
        <div className="sub-container">
            <div className="sub-content-container">{outer}</div>
        </div>
    );
};

export { ProjectOverviewBare, ProjectOverview };
