import * as React from 'react';
import { useDispatch } from 'react-redux';
import { actions as routerActions } from 'redux-router5';
import { ReferrerTypes } from 'reports/analytics/ReferrerTypes';
import { Price } from 'reports/models/stripe';

import ExpiredAccountDialog from 'reports/modules/auth/components/ExpiredAccountDialog';

export const ExpiredBoundary = ({ user, route, children }) => {
    const [isOpen, setOpen] = React.useState(false);
    const dispatch = useDispatch();
    const redirectToCheckoutFlow = (interval: string, price: Price) => {
        return dispatch(
            routerActions.navigateTo('app.settings.team.billing', {
                interval,
                price,
                dialog: 'initial',
                referrer: ReferrerTypes.expired_account_dialog,
            }),
        );
    };

    const dialog = (
        <ExpiredAccountDialog
            user={user}
            isOpen={isOpen}
            onClose={() => setOpen(false)}
            redirectToCheckoutFlow={redirectToCheckoutFlow}
        />
    );

    React.useEffect(() => {
        if (user.isExpired()) {
            if (user.latest_subscription?.is_pay_by_invoice && user.latest_subscription?.cleaned_status === 'unpaid') {
                setOpen(false);
            } else if (
                route &&
                !route.name.startsWith('app.settings.team') &&
                !route.name.startsWith('app.settings.user.features') &&
                !route.name.startsWith('app.settings.hs-admin')
            ) {
                setOpen(true);
            } else {
                setOpen(false);
            }
        } else {
            setOpen(false);
        }
    }, [route?.name, user.user_id]);

    return (
        <>
            {dialog}
            {children}
        </>
    );
};
export default ExpiredBoundary;
