import React from 'react';
import { Outlet, useLoaderData, useOutletContext } from 'react-router-dom';

import { ProjectContext } from 'frontend/projects/use-project';

import { Report } from 'reports/models/report';
import { Project } from 'reports/models/project';

type ProjectReportContext = {
    printableReady: Promise<void>;
    project: Project;
    report: Report;
};

const ProjectReportBase = () => {
    const { project, printableReady } = useOutletContext() as ProjectContext;
    const report = useLoaderData() as Report;

    if (!report) {
        throw new Response('Not Found', { status: 404 });
    }

    return <Outlet context={{ printableReady, project, report } satisfies ProjectReportContext} />;
};

export { ProjectReportBase, ProjectReportContext };
