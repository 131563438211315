import { get } from 'lodash';

import * as React from 'react';
import { connect } from 'react-redux';
import { createRoutePath } from 'reports/routing/common';

import { HTMLTable, Button, Checkbox, Alignment } from '@blueprintjs/core';
import JSZip from 'jszip';

import * as io from 'helioscope/app/utilities/io';
import * as pusher from 'helioscope/app/utilities/pusher';

import * as analytics from 'reports/analytics';

import * as sim from 'reports/models/simulation';
import * as des from 'reports/models/design';
import * as rep from 'reports/models/report';
import * as tm from 'reports/models/team';
import * as proj from 'reports/models/project';
import * as fc from 'reports/models/field_component';
import * as auth from 'reports/modules/auth';

import projectSelectors from 'reports/modules/project/selectors';
import { actions as projectActions } from 'reports/modules/project';
import request from 'reports/modules/request';
import { addPromiseToasts } from 'reports/modules/Toaster';
import { exportSLD } from 'reports/modules/project/export_sld.js';

import { IAppState, IWithRouteProps } from 'reports/types';
import { styled } from 'reports/styles/styled-components';
import { bindActions } from 'reports/utils/redux';

const Table = styled(HTMLTable).attrs({
    striped: true,
    bordered: true,
    condensed: true,
})`
    width: 100%;
    margin-bottom: 10px;

    th,
    td {
        text-align: left;
    }

    tr.disabled > td {
        color: gray;
    }
`;

interface IOwnProps {
    project: proj.Project;
}
type IStateProps = ReturnType<typeof mapStateToProps>;
interface IDispatchProps {
    loadFieldComponents: (designId: number) => Promise<fc.FieldComponent[]>;
}
type IProps = IOwnProps & IStateProps & IDispatchProps & IWithRouteProps;

interface IDocument {
    name: string;
    fetcher: (() => Promise<Blob>) | null;
    asset?: {
        assetType?: string;
        assetId?: number;
    };
}
interface IState {
    userSelection: Set<string> | null;
}

async function fetchBlobFromURL(linkUrl): Promise<Blob> {
    return await request
        .get(linkUrl)
        .responseType('blob')
        .then((response) => response.body);
}

async function downloadBlob(blobPromise, fileName) {
    await addPromiseToasts(
        blobPromise.then((blob) => io.downloadBlob(blob, fileName)),
        {
            initial: `Downloading "${fileName}"...`,
            onSuccess: `Successfully downloaded "${fileName}".`,
            onCatch: `Error downloading "${fileName}".`,
        },
        true,
    );
}

class ProjectExportTable extends React.PureComponent<IProps, IState> {
    state: IState = { userSelection: null };

    async fetchReport(report) {
        const reportUrl = createRoutePath(
            'app.projects.project.report.view',
            {
                projectId: this.props.project.project_id,
                slug: report.slug,
            },
            '',
            {
                reportId: report.report_id,
            },
        );

        const response = await request.post('/api/render/').send({ url: reportUrl }).query({ async: true });

        const { channel } = response.body;
        const { download_url } = await pusher.promiseFromChannel(channel);
        return await fetchBlobFromURL(download_url);
    }

    static async fetchHourlyResults(simulation) {
        const zipBlob = await sim.api.hourly_results_zip.request({
            simulation_id: simulation.simulation_id,
        });
        const zip = await JSZip.loadAsync(zipBlob);
        const filenames = Object.keys(zip.files);
        if (filenames.length !== 1 || !filenames[0].endsWith('.csv')) {
            throw `Expected exactly one csv file in hourly perf zip. Instead, got ${filenames.length}`;
        }

        return zip.file(filenames[0]).async('blob');
    }

    async downloadZip(selection, documents: { [key: string]: IDocument }, design, teamId) {
        const fileNames: string[] = [];
        const fetchPromises: Promise<Blob>[] = [];
        for (const id of selection) {
            const { name, fetcher } = documents[id];

            let fileName = name;
            // Handle duplicate names. I.e. 'Test Proposal.pdf' -> 'Test Proposal (1).pdf'
            for (let counter = 1; fileNames.includes(fileName); counter += 1) {
                fileName = name.replace(/(.[a-zA-Z]+)$/, ` (${counter})$1`);
            }

            if (fetcher) {
                fileNames.push(fileName);
                fetchPromises.push(fetcher());
            }
        }

        const zipFile = Promise.all(fetchPromises).then((blobs) => {
            const zip = new JSZip();
            for (const [i, blob] of blobs.entries()) {
                zip.file(fileNames[i], blob);
            }
            return zip.generateAsync({ type: 'blob' });
        });

        await downloadBlob(zipFile, this.prefixFilename('Export.zip'));
        analytics.trackExport({
            project_id: this.props.project.project_id,
            team_id: teamId,
            design_id: design.design_id,
            file_type: 'project_export',
            export_settings: this.props.project.team.project_export_settings,
        });
    }

    handleSelect(id, defaultSelection) {
        const selected = this.state.userSelection || new Set<string>(defaultSelection);
        if (selected.has(id)) {
            selected.delete(id);
        } else {
            selected.add(id);
        }

        this.setState({ userSelection: new Set<string>(selected) });
    }

    prefixFilename(name) {
        return `HelioScope ${this.props.project.name} ${name}`;
    }

    static docId(reportId: number) {
        return `report_${reportId}`;
    }

    createDocs() {
        const {
            project: { primary_design: design },
            simulation,
            loadFieldComponents,
        } = this.props;
        const isSimComplete = simulation && simulation.complete();

        const documents: { [key: string]: IDocument } = {
            design_render: {
                name: 'Design Render.png',
                fetcher:
                    design && design.render_url
                        ? () =>
                              des.api.render.request({
                                  design_id: design.design_id,
                              })
                        : null,
                asset: {
                    assetType: 'design',
                    assetId: design != null ? design.design_id : undefined,
                },
            },
            design_layout_cad: {
                name: 'Layout CAD.zip',
                // don't allow CAD export if design is not completed. currently there's an exception
                // in render_dxf when a design has no field components.
                fetcher:
                    design && design.nameplate
                        ? async () => {
                              const { download_url, channel } = await des.api.render_dxf.request({
                                  design_id: design.design_id,
                              });

                              let fileUrl = download_url;

                              if (fileUrl == null) {
                                  const res = await pusher.promiseFromChannel(channel);
                                  fileUrl = res.download_url as string;
                              }

                              return fetchBlobFromURL(fileUrl);
                          }
                        : null,
                asset: {
                    assetType: 'design',
                    assetId: design != null ? design.design_id : undefined,
                },
            },
            design_sld: {
                name: 'Single-Line Diagram.dxf',
                // don't allow SLD export if design is not completed, since it has no field components.
                fetcher:
                    design && design.nameplate
                        ? async () => {
                              const comps = await loadFieldComponents(design.design_id);
                              return await exportSLD(comps);
                          }
                        : null,
                asset: {
                    assetType: 'design',
                    assetId: design != null ? design.design_id : undefined,
                },
            },
            sim_hourly_results: {
                name: 'Hourly Performance.csv',
                fetcher: isSimComplete ? () => ProjectExportTable.fetchHourlyResults(simulation) : null,
                asset: {
                    assetType: 'simulation',
                    assetId: simulation != null ? simulation.simulation_id : undefined,
                },
            },
        };

        for (const report of this.props.reports) {
            documents[ProjectExportTable.docId(report.report_id)] = {
                name: `${report.name}.pdf`,
                fetcher: () => this.fetchReport(report),
                asset: { assetType: 'report', assetId: report.report_id },
            };
        }
        return documents;
    }

    static createDefaultSelection(team: tm.Team, reports) {
        const defaultSelection = new Set<string>();
        const settings = {
            ...tm.defaultExportSettings(reports),
            ...team.project_export_settings,
        };
        for (const docId of ['design_render', 'design_layout_cad', 'design_sld', 'sim_hourly_results']) {
            if (settings[docId]) {
                defaultSelection.add(docId);
            }
        }
        for (const reportId of settings.report_ids) {
            // Only allow selection of reports that are linked to this team. Helps catch saved settings that
            // are no longer valid, due to a team losing access to a report.
            if (reports.find((report) => report.report_id === reportId)) {
                defaultSelection.add(ProjectExportTable.docId(reportId));
            }
        }
        return defaultSelection;
    }

    resetSelection() {
        this.setState({ userSelection: null });
    }

    render() {
        const {
            project: { primary_design: design, team },
            simulation,
            reports,
        } = this.props;

        const documents = this.createDocs();
        const defaultSelection = ProjectExportTable.createDefaultSelection(team, reports);
        const selection = this.state.userSelection || defaultSelection;

        const TableRow = ({ id }) => {
            const name = documents[id] ? documents[id].name : id;
            const fetcher = documents[id] ? documents[id].fetcher : null;
            return (
                <tr className={fetcher ? undefined : 'disabled'}>
                    <td>
                        {fetcher ? (
                            <a
                                href="#"
                                onClick={() => {
                                    downloadBlob(fetcher(), this.prefixFilename(name));
                                    analytics.trackExport({
                                        project_id: this.props.project.project_id,
                                        team_id: this.props.user.team_id,
                                        design_id: design.design_id,
                                        file_type: id.startsWith('report_') ? 'report' : id,
                                        file_name: name,
                                        asset_type: get(documents[id], 'asset.assetType'),
                                        asset_id: get(documents[id], 'asset.assetId'),
                                    });
                                }}
                            >
                                {name}
                            </a>
                        ) : (
                            name
                        )}
                    </td>
                    <td>
                        <Checkbox
                            checked={selection.has(id)}
                            disabled={!fetcher}
                            onChange={() => this.handleSelect(id, defaultSelection)}
                            alignIndicator={Alignment.RIGHT}
                        />
                    </td>
                </tr>
            );
        };

        return (
            <div>
                <Table>
                    <colgroup>
                        <col />
                        <col style={{ width: '45px' }} />
                    </colgroup>
                    <thead>
                        <tr>
                            <th>{`Design Files (${design ? design.description : 'None Available'})`}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <TableRow id={'design_render'} />
                        <TableRow id={'design_layout_cad'} />
                        <TableRow id={'design_sld'} />
                    </tbody>
                    <thead>
                        <tr>
                            <th>
                                {'Energy Simulation' + (simulation && simulation.complete() ? '' : ' (Not Available)')}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <TableRow id={'sim_hourly_results'} />
                    </tbody>
                    <thead>
                        <tr>
                            <th>Reports</th>
                        </tr>
                    </thead>
                    <tbody>
                        {reports.length > 0 ? (
                            reports
                                .filter((report) => report.bookmarked())
                                .map((report) => (
                                    <TableRow key={report.report_id} id={ProjectExportTable.docId(report.report_id)} />
                                ))
                        ) : (
                            <TableRow id="No Reports Available" />
                        )}
                    </tbody>
                </Table>
                <div style={{ float: 'right' }}>
                    <Button
                        text="Reset Default Selection"
                        onClick={() => this.resetSelection()}
                        disabled={!this.state.userSelection}
                        style={{ marginRight: '8px' }}
                    />
                    <Button
                        text="Download"
                        onClick={() => this.downloadZip(selection, documents, design, this.props.user.team_id)}
                        disabled={selection.size === 0}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: IAppState, ownProps: IOwnProps) => {
    return {
        reports: rep.selectors.all(state),
        simulation: projectSelectors.primarySimulation(state, ownProps),
        user: auth.selectors.getUser(state)!,
    };
};

const mapDispatchToProps = bindActions(() => ({
    loadFieldComponents: (designId) => projectActions.loadAndParseFieldComponents(designId),
}));

export default connect(mapStateToProps, mapDispatchToProps)(ProjectExportTable);
