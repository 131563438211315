import { useLoaderData, useOutletContext } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { selectors as projectSelectors, Project } from 'reports/models/project';

import { IAppState } from 'reports/store';

type ProjectContext = {
    project: Project;
    printableReady: Promise<void>;
};

const useProject = (): { printableReady: Promise<void>; project?: Project } => {
    const loaderData = useLoaderData() as ProjectContext | null;

    if (loaderData === null) {
        throw new Response('Not Found', { status: 404 });
    }

    const project = useSelector((state) => projectSelectors.byObject(state as IAppState, loaderData.project));

    return { printableReady: loaderData.printableReady, project: project ?? undefined };
};

const useProjectContext = () => useOutletContext() as ProjectContext;

export { ProjectContext, useProject, useProjectContext };
