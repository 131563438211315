import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import * as analytics from 'reports/analytics';
import { IAppState } from 'reports/types';

import { Card } from '@stripe/stripe-js';

import { CardElement } from '@stripe/react-stripe-js';

import * as cfg from 'reports/config';

import { Form } from 'reports/components/forms';
import { DialogStep, DialogStepId, Intent, MultistepDialog } from '@blueprintjs/core';

import { useBillingContext } from 'reports/modules/settings/billing/Context';

import { useStripeData, StripeContainer } from './StripeContainer';

import { getPrice, getPriceFromProduct, handleFormException, hasPrice } from 'reports/modules/settings/billing';

import * as usr from 'reports/models/user';
import * as sub from 'reports/models/subscription';
import * as prc from 'reports/models/stripe/price';
import { api as CustomerAPI } from 'reports/models/stripe/stripe_customer';

import { Licenses } from './Licenses';
import { PlanPayment } from './PlanPayment';
import { PlanSelection } from './PlanSelection';
import { Toaster } from 'reports/modules/Toaster';

import { styled } from 'reports/styles/styled-components';
import { useSubscriptionCustomer, useWatchSubscription } from 'reports/modules/settings/billing/Hooks';

const ToastSummary = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const StyledMultistepDialog = styled(MultistepDialog)`
    width: 1085px;
`;

interface Props {
    closeDialog: () => void;
    isOpen: boolean;
    user: usr.User;
}

interface PurchaseDialogFormData {
    card: Card | typeof CardElement;
    paymentMethod: string;
    email: string;
    interval: string;
    price: prc.Price;
    quantity: number;
    purchaseOrder: string;
    touAccepted: boolean;
}

const _PurchaseDialog = ({ closeDialog, isOpen, user }: Props) => {
    const dispatch = useDispatch();

    const billingContext = useBillingContext();

    const createSubscription = (args: sub.NewSubscriptionForm) => dispatch(sub.api.create(args));
    const deleteSubscription = ({ external_id }) => dispatch(sub.api.delete({ external_id }));
    const refreshSubscription = ({ external_id }) => dispatch(sub.api.refreshCache({ external_id }));
    const refreshUser = ({ email }: { email: string }) => dispatch(usr.api.get({ email }));
    const createStripeCustomer = () => dispatch(CustomerAPI.create({}));
    const config = useSelector((state) => cfg.selectors.getConfig(state as IAppState));

    const { customer: stripeCustomer, setCustomer } = useSubscriptionCustomer(user.team.latest_subscription);

    const { basicPrices, proPrices, confirmCardPayment, validateBillingInfo } = useStripeData();

    const [taxIncluded, setTaxIncluded] = React.useState(false);

    const eventForDialogSteps = {
        plan: {
            licenses: 'checkout.select_plan_next',
        },
        licenses: {
            plan: 'checkout.add_license_back',
            confirm: 'checkout.add_license_next',
        },
        confirm: {
            licenses: 'checkout.confirm_and_pay_back',
        },
    };

    const onPaymentComplete = async (event) => {
        if (user.latest_subscription_external_id) {
            tryRefreshSubscription();
        }
        await refreshUser(user);
        closeDialog();

        // If this callback got an event, we called it from the Pusher channel instead of the timeout
        if (event) {
            Toaster.show({
                icon: 'tick',
                intent: Intent.SUCCESS,
                message: (
                    <ToastSummary>
                        <span>Successfully purchased plan</span>
                    </ToastSummary>
                ),
                timeout: 5000,
            });
        }
    };

    const [setWatchedSubID, watchedSubID, paymentSuccessNotified] = useWatchSubscription(
        null,
        'invoice.paid',
        onPaymentComplete,
    );

    const createNewSubscription = async (formData: PurchaseDialogFormData) => {
        const { email, price, quantity, purchaseOrder, paymentMethod, touAccepted } = formData;

        const { billingDetails } = await validateBillingInfo(formData);
        const { address, name } = billingDetails.value;

        const subscription = await createSubscription({
            address,
            email,
            name,
            quantity,
            purchase_order: purchaseOrder,
            stripe_price: price?.id,
            tou_accepted: touAccepted,
            collection_method: paymentMethod === 'invoice' ? 'send_invoice' : 'charge_automatically',
        });

        if (paymentMethod === 'credit_card') {
            const paymentIntent = subscription.latest_invoice!.payment_intent;
            const handleCardError = async () => await clearIncompleteSubscription(subscription);
            const amount = subscription.latest_invoice!.amount_due;
            await confirmCardPayment(paymentIntent.client_secret, amount, handleCardError);
        } else {
            await refreshUser(user);
            closeDialog();
            Toaster.show({
                icon: 'tick',
                intent: Intent.SUCCESS,
                message: (
                    <ToastSummary>
                        <span>Successfully subscribed to {price.nickname} plan</span>
                    </ToastSummary>
                ),
                timeout: 5000,
            });
        }

        setWatchedSubID(subscription.external_id);
        return subscription;
    };

    const onSubmit = async (formData: PurchaseDialogFormData) => {
        analytics.track('checkout.confirm_and_pay', {
            new_subscription_values: {
                interval: formData.interval,
                price: formData.price.product.name,
                quantity: formData.quantity,
            },
            collection_method: formData.paymentMethod === 'invoice' ? 'send_invoice' : 'charge_automatically',
        });
        return await createNewSubscription(formData);
    };

    const clearIncompleteSubscription = async (subscription?: sub.Subscription) => {
        if (subscription && subscription.status === 'incomplete') {
            // Call API to cancel subscription
            await deleteSubscription({ external_id: subscription.external_id });
        }
    };

    if (!basicPrices.length || !proPrices.length || !config?.google_maps_api_key) {
        return <></>;
    }

    const getInitialPrice = () =>
        billingContext.product && billingContext.interval
            ? getPriceFromProduct(billingContext.interval, billingContext.product, [...basicPrices, ...proPrices])
            : getPrice(basicPrices, 'month');

    const tryRefreshSubscription = async () => {
        if (user.latest_subscription) {
            try {
                await refreshSubscription({ external_id: user.latest_subscription_external_id });
            } catch (exc) {
                console.error('Failed to refresh subscription', exc);
            }
        }
    };

    return (
        <Form<PurchaseDialogFormData, sub.Subscription>
            baseValue={{
                price: getInitialPrice(),
                email: stripeCustomer?.email || user.email,
                interval: billingContext.interval || 'month',
                paymentMethod: 'credit_card',
                quantity: 1,
            }}
            exceptionHandler={handleFormException}
            keepStateOnSubmit={true}
            onSubmit={(formData: PurchaseDialogFormData) => onSubmit(formData)}
        >
            {({
                clearForm,
                formData: { card, email, interval, billingDetails, paymentMethod, price, quantity, touAccepted },
                formErrors,
                submitForm,
                submitting,
                updateValue,
                updateValues,
            }) => {
                const formSubmitting: boolean = !!(
                    submitting ||
                    (watchedSubID && !paymentSuccessNotified && paymentMethod === 'credit_card')
                );

                const promptTermsOfUse = !user.tou_accepted;

                const onClose = async () => {
                    analytics.track('checkout.close', {});
                    if (user.latest_subscription_external_id) {
                        tryRefreshSubscription();
                    }
                    await refreshUser(user);
                    await clearIncompleteSubscription(user.subscription);
                    clearForm();
                    closeDialog();
                };

                const switchToAnnual = () => {
                    const newPrice = hasPrice(basicPrices, price)
                        ? getPrice(basicPrices, 'year')
                        : getPrice(proPrices, 'year');

                    updateValue('interval', 'year');
                    updateValue('price', newPrice);

                    analytics.track('checkout.switch_to_annual', {});
                };

                return (
                    <StyledMultistepDialog
                        canEscapeKeyClose={false}
                        canOutsideClickClose={false}
                        onChange={(newDialogStepId: DialogStepId, prevDialogStepId: DialogStepId) => {
                            if (prevDialogStepId === 'confirm' && newDialogStepId !== 'confirm') {
                                updateValue('card', undefined);
                            }
                            const eventName = eventForDialogSteps[prevDialogStepId][newDialogStepId];
                            analytics.track(eventName, {
                                current_subscription_values: {},
                                new_subscription_values: {
                                    interval,
                                    quantity,
                                    price: price.product.name,
                                },
                            });
                        }}
                        finalButtonProps={{
                            text: paymentMethod !== 'invoice' ? 'Confirm and Pay' : 'Confirm',
                            onClick: () =>
                                submitForm({
                                    card,
                                    email,
                                    price,
                                    quantity,
                                }),
                            disabled:
                                !taxIncluded ||
                                formSubmitting ||
                                (paymentMethod === 'credit_card' && !card?.complete) ||
                                (paymentMethod === 'invoice' && interval === 'month') ||
                                (promptTermsOfUse && !touAccepted),
                            loading: formSubmitting,
                        }}
                        title={'Choose Plan'}
                        isOpen={isOpen}
                        closeButtonProps={{
                            disabled: formSubmitting,
                        }}
                        backButtonProps={{
                            disabled: formSubmitting,
                        }}
                        onOpening={async () => {
                            if (!user.team.stripe_customer_id) {
                                setCustomer(await createStripeCustomer());
                                await refreshUser({ email: user.email });
                            }
                            analytics.track('checkout.open', {
                                referrer: `${billingContext.referrer}_choose_plan`,
                            });
                        }}
                        onClose={onClose}
                        initialStepIndex={0}
                    >
                        <DialogStep
                            nextButtonProps={{ disabled: !price }}
                            id="plan"
                            title="Select Plan"
                            panel={
                                <PlanSelection
                                    basicPrices={basicPrices}
                                    proPrices={proPrices}
                                    interval={interval}
                                    price={price}
                                    onUpdate={({ interval, price }) => {
                                        updateValues({
                                            interval,
                                            price,
                                            quantity: 1,
                                        });
                                    }}
                                    referrer="checkout_dialog"
                                    canSelect
                                />
                            }
                        />
                        <DialogStep
                            id="licenses"
                            title="Add Licenses"
                            panel={<Licenses interval={interval} price={price} quantity={quantity} user={user} />}
                        />
                        <DialogStep
                            id="confirm"
                            title={paymentMethod !== 'invoice' ? 'Confirm and Pay' : 'Confirm'}
                            panel={
                                <PlanPayment
                                    collectBillingInformation={true}
                                    formErrors={formErrors}
                                    mapsAPIKey={config?.google_maps_api_key}
                                    paymentMethod={paymentMethod}
                                    address={billingDetails?.address}
                                    price={price}
                                    quantity={quantity}
                                    submitting={formSubmitting}
                                    user={user}
                                    subscription={user.subscription}
                                    setTaxIncluded={setTaxIncluded}
                                    basicPrices={basicPrices}
                                    proPrices={proPrices}
                                    interval={interval}
                                    switchToAnnual={switchToAnnual}
                                    promptTermsOfUse={promptTermsOfUse}
                                />
                            }
                        />
                    </StyledMultistepDialog>
                );
            }}
        </Form>
    );
};

const PurchaseDialog = (props) => {
    return (
        <StripeContainer>
            <_PurchaseDialog {...props} />
        </StripeContainer>
    );
};

export { PurchaseDialog, useWatchSubscription };
