import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet, useLoaderData, useOutletContext } from 'react-router-dom';

import { Project } from 'reports/models/project';
import { Report, selectors as reportSelectors } from 'reports/models/report';
import { IAppState } from 'reports/store';

const ReportBase = () => {
    // The project pulled from the outlet context is from a selector in ReportsBase
    // and it is up to date.
    const { printableReady, project } = useOutletContext() as {
        printableReady: Promise<void>;
        project: Project;
    };
    const reportToSelect = useLoaderData() as Report;
    const report = useSelector((state) =>
        reportSelectors.byObject(state as IAppState, {
            report_id: reportToSelect.report_id,
        }),
    );

    if (!project || !report) {
        throw new Response('Not Found', { status: 404 });
    }

    const context = { printableReady, project, report };
    return <Outlet context={context} />;
};

export { ReportBase };
