import React from 'react';

import { useNavigate, useOutletContext } from 'react-router-dom';

import { ReportLibrary } from 'reports/modules/report/components/ReportLibrary';
import { ReportPreview as BetaReportPreview } from 'reports/modules/report/components/ReportPreview';
import { Report } from 'reports/models/report';
import { Project } from 'reports/models/project';
import { createRoutePath } from 'reports/routing/common';

const ReportPreview = () => {
    const { printableReady, project, report } = useOutletContext() as {
        printableReady: Promise<void>;
        project: Project;
        report: Report;
    };

    const navigate = useNavigate();
    const navigateToProjects = () =>
        navigate({
            pathname: '/projects',
        });

    const navigateToReport = (project: Project, report: Report) =>
        navigate(
            createRoutePath('/reports/:slug/edit', { slug: report.slug }, '', {
                projectId: String(project.project_id),
                reportId: String(report.report_id),
            }),
        );

    const navigateToReports = (project: Project) =>
        navigate(createRoutePath('/reports', {}, '', { projectId: String(project.project_id) }));

    const navigateToReportPreview = (project: Project, report: Report) =>
        navigate(
            createRoutePath('/reports/:slug/preview', { slug: report.slug }, '', {
                projectId: String(project.project_id),
                reportId: String(report.report_id),
            }),
        );

    return (
        <ReportLibrary
            inlineControls={true}
            navigateToProjects={navigateToProjects}
            navigateToReport={navigateToReport}
            navigateToReportPreview={navigateToReportPreview}
            preview={
                <BetaReportPreview
                    navigateToReport={navigateToReport}
                    navigateToReportPreview={navigateToReportPreview}
                    navigateToReports={navigateToReports}
                    printableReady={printableReady}
                    project={project}
                    report={report}
                />
            }
            project={project}
            reportId={report.report_id}
        />
    );
};

export { ReportPreview };
