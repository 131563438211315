import { actions as routerActions } from 'redux-router5';
import { Intent } from '@blueprintjs/core';

import { selectors as authSelectors } from 'reports/modules/auth';
import * as authTokens from 'reports/models/auth_token';
import * as des from 'reports/models/design';
import * as projFinTemp from 'reports/models/project_financial_template';
import * as finTemp from 'reports/models/financial_template';
import * as inc from 'reports/models/incentive';
import * as proj from 'reports/models/project';
import * as rep from 'reports/models/report/Report';
import * as scenario from 'reports/models/scenario';
import * as horizonProfile from 'reports/models/horizon_profile';
import * as ur from 'reports/models/utility_rate';
import * as pd from 'reports/models/power_device';
import * as pdChar from 'reports/models/power_device/PowerDeviceCharacterization';
import * as mod from 'reports/models/module';
import * as modChar from 'reports/models/module/ModuleCharacterization';
import * as pro from 'reports/models/profile';
import * as wr from 'reports/models/wire';
import * as acc from 'reports/models/ac_config';

import { helioscopeAdmin, UnauthorizedError } from 'reports/modules/auth/permissions';
import { actions as projActions } from 'reports/modules/project';
import { actions as reportActions } from 'reports/modules/report';
import { Toaster } from 'reports/modules/Toaster';
import { installProjectListeners, removeProjectListeners } from 'reports/modules/project/listeners';

import { makeRoute, IAppRoute, IRouteBase } from '../common';
import AccountSettingsConfig from './AccountSettingsConfig';
import { getClassicUrl } from 'reports/utils/url';

const restrictFinancials = (getState) => {
    const user = authSelectors.getUser(getState());
    const canViewFinancials = user?.hasFinancialsAccess();
    if (user == null || !canViewFinancials) {
        throw new UnauthorizedError();
    }
};

const activateRouteOpts: IRouteBase = {
    onEnter: async (_dispatch, _getState, { toParams }) => {
        try {
            // Now that we only have a single signup page, we want the user experience to be consistent
            // So, we send users back to Classic UI to perform activation
            window.location.href = getClassicUrl(`activate/${toParams.auth_token}`);
        } catch {
            Toaster.show({
                message: 'Could not activate account with that token',
                intent: Intent.DANGER,
            });
        }
    },
};

const projectRouteOpts: IRouteBase = {
    selectors: {
        project: (state, { projectId }) => proj.selectors.byObject(state, { project_id: projectId }),
    },
    onEnter: async (dispatch, _getState, { toParams }) => {
        installProjectListeners(toParams.projectId, dispatch);
        return await dispatch(projActions.loadProjectState(toParams.projectId));
    },
    onExit: (_dispatch, _getState, { fromParams }) => {
        removeProjectListeners(fromParams.projectId);
    },
};

const projectOverviewRouteOpts: IRouteBase = {
    onEnter: async (dispatch, getState, { toParams }) => {
        const user = authSelectors.getUser(getState());
        const canViewFinancials = user?.hasFinancialsAccess();
        if (!canViewFinancials) {
            return [];
        }
        return await Promise.all([
            dispatch(
                projFinTemp.api.index({
                    project_id: toParams.projectId as number,
                }),
            ),
        ]);
    },
};

const finTemplateRouteOpts: IRouteBase = {
    selectors: {
        template: (state, { finTemplateId }) =>
            finTemp.selectors.byObject(state, {
                financial_template_id: finTemplateId,
            }),
    },
    onEnter: async (dispatch, _getState, { toParams }) => {
        await dispatch(finTemp.api.get({ financial_template_id: toParams.finTemplateId }));
    },
};

const finConfigsRouteOpts: IRouteBase = {
    onEnter: async (dispatch, getState, { toParams }) => {
        restrictFinancials(getState);
        return await Promise.all([dispatch(projFinTemp.api.index({ project_id: toParams.projectId as number }))]);
    },
};

const finConfigRouteOpts: IRouteBase = {
    selectors: {
        config: (state, { finConfigId }) =>
            projFinTemp.selectors.byObject(state, {
                project_financial_template_id: finConfigId,
            }),
    },
};

const rateEditRouteOpts: IRouteBase = {
    selectors: {
        utilityRate: (state, { rateId }) =>
            ur.selectors.byObject(
                state,
                { utility_rate_id: rateId },
                {
                    creator: true,
                    last_modified_by_user: true,
                },
            ),
    },
    onEnter: async (dispatch, _getState, { toParams }) => {
        await dispatch(ur.api.get({ utility_rate_id: toParams.rateId }));
    },
};

const projectReportRouteOpts: IRouteBase = {
    selectors: {
        report: (state, { slug, reportId }) => {
            if (reportId != null) {
                return rep.selectors.byId(state, reportId);
            }
            return rep.selectors.bySlug(state, slug);
        },
    },
    onEnter: async (dispatch, getState, { toParams: { reportId, projectId, slug } }) => {
        let cleanedReportId: number | string = reportId;
        const slugString = slug as string;

        if (cleanedReportId == null) {
            // attempt to determine the report id by matching on the slug

            let report = rep.selectors.bySlug(getState(), slug);

            if (report == null) {
                const potentialReports = await dispatch(
                    rep.api.index({
                        q: slugString!.replace(/-/g, ' '),
                    }),
                );

                const matchedReports = potentialReports.filter((r) => r.slug === slug);

                if (matchedReports == null || matchedReports.length !== 1) {
                    throw new Error(`cant find report for ${reportId}/${slug}`);
                }
                report = matchedReports[0]!;
            }

            if (!report!.basic_report) {
                restrictFinancials(getState);
            }

            cleanedReportId = report!.report_id;
        }

        // report should already be loaded for sidebar index, ensure latest and load files.
        // Get all report configurations associated with the current project, by passing in projectId.
        await dispatch(
            rep.api.get({
                report_id: cleanedReportId,
                load_files: true,
                project_id: projectId,
            }),
        );
    },
};

const reportsRouteOpts: IRouteBase = {
    selectors: {
        project: (state, params) => proj.selectors.byObject(state, { project_id: params.projectId }),
    },
    onEnter: async (dispatch, getState, { toParams }) => {
        const { projectId } = toParams;

        restrictFinancials(getState);

        if (projectId != null) {
            installProjectListeners(projectId, dispatch);
            return await dispatch(projActions.loadProjectState(projectId));
        }

        const defaultProjectId = await dispatch(reportActions.getDefaultProjectId());
        if (defaultProjectId) {
            dispatch(
                routerActions.navigateTo('app.reports', {
                    ...toParams,
                    projectId: defaultProjectId,
                }),
            );
        }
    },
    onExit: (_dispatch, _getState, { fromParams: { projectId } }) => {
        if (projectId != null) {
            removeProjectListeners(projectId);
        }
    },
};

const reportRouteOpts: IRouteBase = {
    selectors: {
        report: (state, { slug, reportId }) => {
            if (reportId != null) {
                return rep.selectors.byId(state, reportId);
            }
            return rep.selectors.bySlug(state, slug);
        },
    },
    onEnter: async (dispatch, _getState, { toParams: { reportId } }) => {
        await dispatch(rep.api.get({ report_id: reportId, load_files: true }));
    },
};

const incentivesRouteOpts: IRouteBase = {
    onEnter: async (_dispatch, getState) => {
        restrictFinancials(getState);
    },
};

const finTemplatesRouteOpts: IRouteBase = {
    onEnter: async (_dispatch, getState) => {
        restrictFinancials(getState);
    },
};

const ratesRouteOpts: IRouteBase = {
    onEnter: async (_dispatch, getState) => {
        restrictFinancials(getState);
    },
};

const designerRouteOpts: IRouteBase = {
    defaultParams: { subpath: '' },
    onEnter: async (dispatch, _getState, { toParams: { designId } }) => {
        await dispatch(des.api.get({ design_id: designId }));
    },
    onExit: async (dispatch, _getState, { fromParams, toParams }) => {
        if (toParams.projectId === fromParams.projectId) {
            // reload the Design, now that angular has saved it
            dispatch(des.api.get({ design_id: fromParams.designId }));

            // clear field segments as they may have been changed
            dispatch(projActions.clearFieldComponents());
        }
    },
    selectors: {
        design: (state, { designId }) => des.selectors.byId(state, designId),
    },
};

const authTokensRouteOpts: IRouteBase = {
    onEnter: async (dispatch) => {
        await dispatch(authTokens.api.index());
    },
};

const incentiveEditRouteOpts: IRouteBase = {
    selectors: {
        incentive: (state, { incentiveId }) => inc.selectors.byObject(state, { incentive_id: incentiveId }),
    },
    onEnter: async (dispatch, _getState, { toParams }) => {
        await dispatch(inc.api.get({ incentive_id: toParams.incentiveId as number }));
    },
};

const powerDeviceRouteOpts: IRouteBase = {
    selectors: {
        powerDevice: (state, { powerDeviceId }) => pd.selectors.byId(state, powerDeviceId, { team: true }),
        characterization: (state, { characterizationId }) => pdChar.selectors.byId(state, characterizationId),
    },
    onEnter: async (dispatch, _getState, { toParams }) => {
        // The characterization API returns both the characterization and the parent device.
        // Fetch all characterizations since they'll be needed by the CharacterizationSelect and most devices
        // just have 1 characterization. The current max is 3 chars on one device.
        await dispatch(pdChar.api.index({ power_device_id: toParams.powerDeviceId as number }));
    },
};

const adminRouteOpts: IRouteBase = {
    onEnter: (_dispatch, getState) => {
        const user = authSelectors.getUser(getState());
        if (user == null || !helioscopeAdmin(user)) {
            throw new UnauthorizedError();
        }
    },
};

const conditionsAppRoute: IAppRoute = makeRoute(
    'conditions',
    '/conditions',
    {
        onEnter: async (dispatch, getState, { toParams }) => {
            const project = proj.selectors.byId(getState(), toParams.projectId)!;

            await dispatch(scenario.api.index({ project_id: project.project_id }));

            if (toParams.scenarioId == null && project.primary_scenario_id != null) {
                const routeParams = {
                    projectId: toParams.projectId,
                    scenarioId: project.primary_scenario_id,
                };
                dispatch(
                    routerActions.navigateTo(
                        'app.projects.project.conditions.condition',
                        routeParams,
                        { replace: true }, // don't add this redirect to history
                    ),
                );
            }
        },
    },
    [
        makeRoute(
            'condition',
            '/:scenarioId',
            {
                selectors: {
                    scenario: (state, { scenarioId }) => scenario.selectors.byId(state, scenarioId),
                },
                onEnter: async (dispatch, _getState, { toParams }: { toParams: any }) => {
                    await dispatch(scenario.api.get({ scenario_id: toParams.scenarioId }));
                },
            },
            [
                makeRoute('edit', '/edit', {
                    onEnter: async (dispatch, _getState, { toParams }: { toParams: any }) => {
                        await dispatch(
                            horizonProfile.api.index({
                                project_id: toParams.projectId,
                            }),
                        );
                    },
                }),
            ],
        ),
    ],
);

const moduleLibraryConfig: IAppRoute = makeRoute('modules', 'modules', {}, [
    makeRoute(
        'module',
        '/:moduleId/characterizations/:characterizationId',
        {
            selectors: {
                module: (state, { moduleId }) => mod.selectors.byId(state, moduleId),
                characterization: (state, { characterizationId }) => modChar.selectors.byId(state, characterizationId),
            },
            onEnter: async (dispatch, _getState, { toParams }) => {
                // The characterization API returns both the characterization and the parent module.
                // Fetch all characterizations since they'll be needed by the CharacterizationSelect and most modules
                // just have 1 or 2 characterizations. The current max is 12 chars on one module (very rare).
                await dispatch(modChar.api.index({ module_id: toParams.moduleId }));
            },
        },
        [makeRoute('preview', '/preview')],
    ),
]);

const weatherSourcesLibraryConfig: IAppRoute = makeRoute('weather_sources', 'weather_sources');

const profileLibraryConfig: IAppRoute = makeRoute('profiles', 'profiles', {}, [
    makeRoute(
        'profile',
        '/:profileId',
        {
            selectors: {
                profile: (state, { profileId }) => pro.selectors.byId(state, profileId),
            },
            onEnter: async (dispatch, _getState, { toParams }) => {
                const profile = await dispatch(pro.api.get({ profile_id: toParams.profileId }));
                if (profile instanceof pro.FinancialProfile) {
                    restrictFinancials(_getState);
                    // Need to load all incentives manually here b/c there's no relationship defined in the Schema
                    await Promise.all(
                        profile.data.incentive_ids.map((incentiveId) =>
                            dispatch(inc.api.get({ incentive_id: incentiveId })),
                        ),
                    );
                }
            },
        },
        [
            makeRoute('preview', '/preview'),
            makeRoute('edit', '/edit', {
                onEnter: async (dispatch, getState, { toParams }) => {
                    const user = authSelectors.getUser(getState());
                    const profile = await dispatch(pro.api.get({ profile_id: toParams.profileId }));

                    if (user?.team_id !== profile.team_id && !user?.is_admin) {
                        throw new UnauthorizedError();
                    }

                    if (profile instanceof pro.ElectricalProfile) {
                        await Promise.all([dispatch(wr.api.index()), dispatch(acc.api.index())]);
                    }
                },
            }),
        ],
    ),
]);

export const routes: IAppRoute[] = [
    {
        name: 'app',
        path: '/?dialog',
        forwardTo: 'app.projects',

        children: [
            AccountSettingsConfig,
            makeRoute('sso-test', 'sso-test', {}, []),
            makeRoute('signup', 'signup', {}, [], false),
            makeRoute('set-password', 'set-password/:token', {}, [], false),
            makeRoute('reset-password', 'reset-password/:token', {}, [], false),
            makeRoute('forgot-password', 'forgot-password', {}, [], false),
            makeRoute('welcome', 'welcome'),
            makeRoute('not-activated', 'not-activated', {}, [], false),
            makeRoute('activate', 'activate/:auth_token', activateRouteOpts, [], false),
            makeRoute(
                'projects',
                'projects',
                {},
                [
                    makeRoute('project', '/:projectId', projectRouteOpts, [
                        makeRoute('overview', '/', projectOverviewRouteOpts),
                        makeRoute('report', '/report/:slug?reportId', projectReportRouteOpts, [
                            makeRoute('view', '/'),
                            makeRoute('configure', '/configure'),
                        ]),
                        makeRoute('designer', '/designer/:designId/*subpath', designerRouteOpts),
                        makeRoute('financial-configurations', '/financial-configurations', finConfigsRouteOpts, [
                            makeRoute('financial-configuration', '/:finConfigId', finConfigRouteOpts),
                        ]),
                        conditionsAppRoute,
                        makeRoute('sharing', '/sharing'),
                        makeRoute('simulation', '/simulation'),
                    ]),
                ],
                false,
            ),
            makeRoute('reports', 'reports?projectId', reportsRouteOpts, [
                makeRoute('report', '/:slug?reportId', reportRouteOpts, [
                    makeRoute('preview', '/preview'),
                    makeRoute('edit', '/edit'),
                ]),
            ]),
            profileLibraryConfig,
            makeRoute('financial-templates', 'financial-models', finTemplatesRouteOpts, [
                makeRoute('financial-template', '/:finTemplateId', finTemplateRouteOpts, [
                    makeRoute('preview', '/preview'),
                    makeRoute('edit', '/edit'),
                ]),
            ]),
            makeRoute('utility-rates', 'utility-rates', ratesRouteOpts, [
                makeRoute('utility-rate', '/:rateId', rateEditRouteOpts),
            ]),
            makeRoute('incentives', 'incentives', incentivesRouteOpts, [
                makeRoute('incentive', '/:incentiveId', incentiveEditRouteOpts),
            ]),
            makeRoute('wires', 'wires', {}, []),
            makeRoute('power-devices', 'components', {}, [
                makeRoute(
                    'power-device',
                    '/:powerDeviceId/characterizations/:characterizationId',
                    powerDeviceRouteOpts,
                    [makeRoute('preview', '/preview')],
                ),
            ]),
            moduleLibraryConfig,
            weatherSourcesLibraryConfig,
            makeRoute('auth-tokens', 'auth-tokens', authTokensRouteOpts),
            makeRoute('ogrenderer', 'render/:designId', {
                strictQueryParams: false,
            }),
            makeRoute('admin', 'admin', adminRouteOpts, [makeRoute('invoice', '/invoice', {})]),
            makeRoute('account', 'account/*subpath', { forwardTo: 'app.settings.user.changepassword' }, []),
        ],
    },
];

export default routes;
