import * as React from 'react';

import { Section2 } from 'reports/components/core/containers';

import { IntegrationCardOverview } from './IntegrationCardOverview';

const TeamIntegrations = () => (
    <Section2 title="Integrations">
        <IntegrationCardOverview />
    </Section2>
);

export { TeamIntegrations };
