import { IconNames } from '@blueprintjs/icons';
import * as React from 'react';

import { LinkButton } from 'reports/components/core/controls';

import * as rep from 'reports/models/report';
import * as proj from 'reports/models/project';

type Props = {
    report: rep.Report;
    project: proj.Project;
};

const ViewReportTemplateButton = ({ report, project }: Props) => (
    <LinkButton
        text="View Report Template"
        icon={IconNames.DOCUMENT_OPEN}
        routeName="app.reports.report.preview"
        routeParams={{
            slug: report.slug,
        }}
        searchParams={{ projectId: String(project.project_id), reportId: String(report.report_id) }}
        target="_blank"
    />
);

export { ViewReportTemplateButton };
