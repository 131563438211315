/*
 * Suggested library editor component hierarchy:
 *    Container
 *        {...editor content}
 *            <ToolsSidebarControl/>
 *        <ToolsSidebar/>
 */
import { noop } from 'lodash';
import * as React from 'react';
import * as ReactDOM from 'react-dom';

import { Classes, Colors, Icon, ITabProps, ITabsProps, Tabs, Tab, TabId } from '@blueprintjs/core';
import { IconName } from '@blueprintjs/icons';

import { Flex } from 'reports/components/core/containers';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const SIDEBAR_WIDTH = 300;

const ContentPane = styled(Flex.Main)`
    padding: 12px;
    overflow-y: auto;
`;

const TabsContainer = styled(Flex.Container)`
    width: ${SIDEBAR_WIDTH}px;

    background-color: ${Colors.LIGHT_GRAY5};
    border-left: 1px solid ${Colors.GRAY5};
    box-shadow: -2px 0px 4px ${Colors.LIGHT_GRAY1};

    // Override default Blueprint tab styles
    .${Classes.TABS}.${Classes.VERTICAL} {
        flex: 1;

        & > .${Classes.TAB_LIST} .${Classes.TAB} {
            text-align: center; // Center tab icons
            padding: 0 2px;
        }
        .${Classes.TAB_PANEL} {
            flex: 1;
            padding: 8px 12px;
        }
    }
`;

interface IToolsSidebarStateContext {
    sidebarOpen: boolean;
    toggleSidebar: () => void;
}

// Context to maintain sidebar portal in parent Container, you shouldn't need to use this unless you are making changes
// to the sidebar portal itself
export const { Provider: ToolsSidebarProvider, Consumer: ToolsSidebarConsumer } = React.createContext<{
    sidebarRef?: React.RefObject<HTMLDivElement>;
}>({ sidebarRef: undefined });

// Context to maintain sidebar state, use to access sidebar state and toggle functionality
export const { Provider: ToolsSidebarStateProvider, Consumer: ToolsSidebarStateConsumer } =
    React.createContext<IToolsSidebarStateContext>({
        sidebarOpen: false,
        toggleSidebar: noop,
    });

interface IState {
    sidebarOpen: boolean;
}

export class Container extends React.PureComponent<{ style?: React.CSSProperties }, IState> {
    sidebarRef: React.RefObject<HTMLDivElement> = React.createRef<HTMLDivElement>();

    state: IState = {
        sidebarOpen: false,
    };

    toggleSidebar = () => this.setState((state) => ({ sidebarOpen: !state.sidebarOpen }));

    render() {
        const { sidebarOpen } = this.state;
        return (
            <ToolsSidebarProvider value={{ sidebarRef: this.sidebarRef }}>
                <ToolsSidebarStateProvider value={{ sidebarOpen, toggleSidebar: this.toggleSidebar }}>
                    <ContentPane style={this.props.style}>{this.props.children}</ContentPane>

                    <div
                        id="tools-sidebar"
                        ref={this.sidebarRef}
                        // ref not supported with styled-components
                        style={{ display: sidebarOpen ? 'flex' : 'none' }}
                    />
                </ToolsSidebarStateProvider>
            </ToolsSidebarProvider>
        );
    }
}

interface ITab extends Omit<ITabProps, 'title'> {
    icon: IconName;
    id: string;
    panel: JSX.Element;
}

interface IToolsSidebar {
    tabs: ITab[];
    onTabChange?(newTabId: TabId, prevTabId: TabId, evt: React.MouseEvent<HTMLElement>): void;
    tabsProps?: ITabsProps;
}

const ToolsSidebarContent: React.SFC<IToolsSidebar> = ({ tabs, onTabChange, tabsProps }) => (
    <TabsContainer>
        <Tabs id="EditorSidebarTabs" onChange={onTabChange} vertical={true} {...tabsProps}>
            {tabs.map(({ id, icon, panel, ...tabProps }) => (
                <Tab id={id} key={id} title={<Icon icon={icon} />} panel={panel} {...tabProps} />
            ))}
        </Tabs>
    </TabsContainer>
);

export const ToolsSidebar: React.SFC<IToolsSidebar> = (props) => (
    <ToolsSidebarConsumer>
        {({ sidebarRef }) =>
            sidebarRef && sidebarRef.current
                ? ReactDOM.createPortal(<ToolsSidebarContent {...props} />, sidebarRef.current)
                : null
        }
    </ToolsSidebarConsumer>
);

/* Generic sidebar control that will toggle sidebar on click
 *
 * If you want to use a custom control:
 * <ToolsSidebarStateConsumer>
 *     {({ toggleSidebar, sidebarOpen }) => <CustomControl onClick={toggleSidebar} active={sidebarOpen}/>}
 * </ToolsSidebarStateConsumer>
 */
export const ToolsSidebarControl = (props) => (
    <ToolsSidebarStateConsumer>
        {({ toggleSidebar }) => <div onClick={toggleSidebar}>{props.children}</div>}
    </ToolsSidebarStateConsumer>
);

export default {
    Container,
    ToolsSidebar,
    ToolsSidebarControl,
};
