import * as React from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import { addPromiseToasts } from 'reports/modules/Toaster';
import { promptRemoveExtension } from 'reports/modules/settings/billing/components/promptRemoveExtension';
import SelfServeForm from 'reports/modules/settings/admin/components/SelfServeForm';
import { SectionTitleWithTag } from 'reports/modules/settings/admin/components/SectionTitleWithTag';
import { ContractConversionButton } from 'reports/modules/settings/admin/components/ContractConversionButton';

import { Section2 } from 'reports/components/core/containers';

import * as ext from 'reports/models/subscription_extension';
import * as sub from 'reports/models/subscription';
import * as tm from 'reports/models/team';
import { User } from 'reports/models/user';

export interface SubscriptionExtensionFormData {
    subscription_extension_enabled: boolean;
    end_date: moment.Moment;
    reason: string;
}

export type FormData = tm.SelfServeSettings & SubscriptionExtensionFormData;

interface Props {
    subscription: sub.Subscription;
    team: tm.Team;
    user: User;
}

const SelfServeView = ({ subscription, team, user }: Props) => {
    const subscriptionExtension = subscription.subscription_extension;

    const dispatch = useDispatch();

    const minDate = subscription.is_closed ? user!.now().toDate() : user!.current_period_end!.toDate();

    const baseValue: FormData = {
        admin_allowance_project_count: team.admin_allowance_project_count,
        consumption_enforcement_period: team.consumption_enforcement_period,
        subscription_extension_enabled: !!subscriptionExtension,
        end_date: subscriptionExtension ? subscriptionExtension.end_date : moment(minDate),
        reason: subscriptionExtension?.reason || '',
    };

    const showConsumptionAllocation =
        tm.SubscriptionType.v2_1_to_1 === team.subscription_type && !team.is_on_custom_plan;

    const updateSelfServeSettings = (formData: tm.SelfServeSettings) =>
        dispatch(
            tm.api.updateSelfServeSettings(
                {
                    admin_allowance_project_count: formData.admin_allowance_project_count,
                    consumption_enforcement_period: formData.consumption_enforcement_period,
                },
                { team_id: team.team_id },
            ),
        );
    const createSubscriptionExtension = (
        formData: Omit<SubscriptionExtensionFormData, 'subscription_extension_enabled'>,
    ) =>
        dispatch(
            ext.api.create({
                reason: formData.reason,
                end_date: formData.end_date.toISOString(),
                subscription_id: subscription.external_id,
            }),
        );
    const updateSubscriptionExtension = (
        formData: Omit<SubscriptionExtensionFormData, 'subscription_extension_enabled'>,
    ) =>
        dispatch(
            ext.api.save({
                reason: formData.reason,
                end_date: formData.end_date.toISOString(),
                subscription_extension_id: subscriptionExtension!.subscription_extension_id,
            }),
        );
    const deleteSubscriptionExtension = ({ subscription_extension_id }) =>
        dispatch(ext.api.delete({ subscription_extension_id }));

    const refreshSubscription = ({ external_id }) => dispatch(sub.api.refreshCache({ external_id }));

    const selfServeSettingsChanged = (formData: FormData) =>
        formData.admin_allowance_project_count !== baseValue.admin_allowance_project_count ||
        formData.consumption_enforcement_period !== baseValue.consumption_enforcement_period;

    const subscriptionExtensionChanged = (formData: FormData) => {
        if (!subscriptionExtension) {
            return formData.subscription_extension_enabled && !!formData.end_date && !!formData.reason;
        }
        return formData.end_date !== subscriptionExtension.end_date || formData.reason !== subscriptionExtension.reason;
    };

    // We know that the extension has been removed if it has an end_date defined to begin with and it has been unchecked
    // at the time of submission.
    const subscriptionExtensionRemoved = (formData: FormData) =>
        !!subscriptionExtension && !formData.subscription_extension_enabled;

    const isDirty = (formData: FormData) =>
        selfServeSettingsChanged(formData) ||
        subscriptionExtensionChanged(formData) ||
        subscriptionExtensionRemoved(formData);

    const onSubmitExtension = async ({ end_date, reason }) => {
        await addPromiseToasts(
            subscriptionExtension
                ? updateSubscriptionExtension({
                      reason,
                      end_date,
                  })
                : createSubscriptionExtension({
                      reason,
                      end_date,
                  }),
            {
                initial: 'Extending subscription...',
                onSuccess: 'Successfully extended subscription.',
                onCatch: 'Error extending subscription.',
            },
        );

        // Refresh the subscription in Redux so that the subscription passed into this form has the new extension info.
        await refreshSubscription({ external_id: subscription.external_id });
    };

    const onSubmit = async (formData) => {
        if (selfServeSettingsChanged(formData)) {
            await addPromiseToasts(updateSelfServeSettings(formData), {
                initial: 'Saving subscription settings for team...',
                onSuccess: 'Successfully saved subscription settings for team.',
                onCatch: 'Error saving subscription settings for team.',
            });
        }

        if (subscriptionExtensionChanged(formData)) {
            return await onSubmitExtension({ end_date: formData.end_date!, reason: formData.reason! });
        }

        if (subscriptionExtensionRemoved(formData)) {
            await promptRemoveExtension({
                subscription,
                subscriptionExtension,
                deleteSubscriptionExtension,
                updateSubscriptionExtension,
            });
        }
    };

    return (
        <Section2
            title={<SectionTitleWithTag subscription={subscription} title="Self-serve Subscription" />}
            contextEl={<ContractConversionButton isConversion={true} userEmail={user.email} />}
        >
            <SelfServeForm
                baseValue={baseValue}
                isDirty={isDirty}
                minDate={minDate}
                onSubmit={onSubmit}
                showConsumptionAllocation={showConsumptionAllocation}
                subscription={subscription}
                user={user}
            />
        </Section2>
    );
};

export default SelfServeView;
