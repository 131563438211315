import React from 'react';

import { useNavigate, useOutletContext } from 'react-router-dom';
import { ReportLibrary } from 'reports/modules/report/components/ReportLibrary';
import { Project } from 'reports/models/project';
import { Report } from 'reports/models/report';
import { createRoutePath } from 'reports/routing/common';

const Reports = () => {
    const navigate = useNavigate();

    const navigateToProjects = () => navigate({ pathname: '/projects' });

    const navigateToReport = (project: Project, report: Report) =>
        navigate(
            createRoutePath('/reports/:slug/edit', { slug: report.slug }, '', {
                projectId: String(project.project_id),
                reportId: String(report.report_id),
            }),
        );

    const navigateToReportPreview = (project: Project, report: Report) =>
        navigate(
            createRoutePath('/reports/:slug/preview', { slug: report.slug }, '', {
                projectId: String(project.project_id),
                reportId: String(report.report_id),
            }),
        );

    const { project } = useOutletContext() as { project: Project | undefined };
    return (
        <ReportLibrary
            inlineControls={true}
            navigateToProjects={navigateToProjects}
            navigateToReport={navigateToReport}
            navigateToReportPreview={navigateToReportPreview}
            project={project}
        />
    );
};

export { Reports };
