import * as React from 'react';

import { Icon } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { Flex } from 'reports/components/core/containers';
import { DropdownMenuItem, StaticSelect } from 'reports/components/core/forms';

import { PAPER_TYPES, PaperType, Document } from 'reports/models/report';

type Props = {
    document: Document;
    onUpdate: (newDoc: Document) => void;
};

const ReportPageSettingControls = React.memo(({ document, onUpdate }: Props) => {
    const { paperType, isLandscape } = document;
    const currPaperType = PAPER_TYPES[paperType];

    const setIsLandscape = (isLandscape: boolean) => onUpdate(document.patch({ isLandscape }));
    const setPaperType = (paperType: PaperType) => onUpdate(document.patch({ paperType }));

    return (
        <Flex.Container alignV={Flex.AlignV.CENTER}>
            <Icon icon={IconNames.ROTATE_DOCUMENT} className="label-icon" />
            <StaticSelect<PaperType>
                items={Object.keys(PAPER_TYPES) as PaperType[]}
                itemRenderer={(id, { handleClick, modifiers }) => (
                    <DropdownMenuItem key={id} title={PAPER_TYPES[id].name} onClick={handleClick} {...modifiers} />
                )}
                onItemSelect={(id) => setPaperType(id)}
                buttonText={currPaperType.name}
            />
            <StaticSelect<boolean>
                items={[true, false]}
                itemRenderer={(itemIsLandscape, { handleClick, modifiers }) => (
                    <DropdownMenuItem
                        key={itemIsLandscape.toString()}
                        title={itemIsLandscape ? 'Landscape' : 'Portrait'}
                        onClick={handleClick}
                        {...modifiers}
                        active={isLandscape === itemIsLandscape}
                    />
                )}
                onItemSelect={(itemIsLandscape) => setIsLandscape(itemIsLandscape)}
                buttonText={isLandscape ? 'Landscape' : 'Portrait'}
            />
        </Flex.Container>
    );
});

export { ReportPageSettingControls };
