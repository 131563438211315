import { createSearchParams, generatePath, URLSearchParamsInit } from 'react-router-dom';
import { routes } from 'reports/routing/routes';

import type { IAppState, AppStore, IParams } from 'reports/types';
import type { IRouteConfig } from 'reports/utils/router';
import * as auth from 'reports/modules/auth';

export type IAppRoute<P extends IParams = any> = IRouteConfig<P, IAppState>;
export type IRouteBase<P extends IParams = any> = Omit<IAppRoute<P>, 'path' | 'name' | 'children'>;

const unauthorizedRedirectHandler = (_router, dependencies: { store: AppStore }) => (toState, _fromState) => {
    const { store } = dependencies;
    const user = auth.selectors.getUser(store.getState());
    // send the user to un-auth'd safe "app" route which currently forwards to app.projects so we have
    // clean state once they login.  Currently many of the paths have little or no protections against
    // a null user so things fail silently un-auth'd but fail visibly once auth'd without cleaning up the state
    if (!user) {
        return Promise.reject({
            redirect: {
                name: 'app',
                params: { postAuthDestinationName: toState.name, postAuthDestinationParams: toState.params },
            },
        });
    }

    return Promise.resolve(true);
};

/**
 * Creates a foxfire route path.
 * @param routeName - the name of the route.
 * @param routeParams - the route params to be added to the route path.
 * @param search - the search string from the current location.
 * @param searchParams - the search params to be added to the search string.
 * @returns string - the foxfire route path.
 */
const createRoutePath = (
    routeName: string,
    routeParams: { [x: string]: any },
    search: string,
    searchParams: URLSearchParamsInit,
) => {
    const path: string = generatePath(routeName.includes('/') ? routeName : routes[routeName], routeParams);
    const routePath: string = path.includes('*subpath')
        ? generatePath(path.replace('subpath', ''), { '*': routeParams.subpath })
        : path;

    const newSearchParams = createSearchParams(searchParams as URLSearchParamsInit);
    const queryParams = generateSearchParams(search, newSearchParams);
    return routePath + queryParams;
};

const generateSearchParams = (search: string, searchParams: URLSearchParamsInit) => {
    const searchParamsString = searchParams.toString();
    if (search && searchParamsString) {
        // In the case in which we have both existing and new search params, we want the new search params to take
        // precedence.
        const currentSearchParams = new URLSearchParams(search);
        const newSearchParams = new URLSearchParams(searchParams.toString());
        newSearchParams.forEach((_, key) => {
            if (currentSearchParams.has(key)) {
                currentSearchParams.delete(key);
            }
        });
        return createSearchParamsString(currentSearchParams.toString(), newSearchParams.toString());
    }
    if (search) {
        return search;
    }
    if (searchParamsString) {
        return `?${searchParamsString}`;
    }
    return '';
};

const createSearchParamsString = (currentSearchParamsString: string, newSearchParamsString: string) => {
    if (currentSearchParamsString && newSearchParamsString) {
        return `?${currentSearchParamsString}&${newSearchParamsString}`;
    } else if (currentSearchParamsString) {
        return `?${currentSearchParamsString}`;
    } else if (newSearchParamsString) {
        return `?${newSearchParamsString}`;
    }
    return '';
};

const makeRoute = (
    name: string,
    path: string,
    options: IRouteBase = {},
    children?: IAppRoute<any>[],
    shouldRedirectUnauthorized: boolean = true,
): IAppRoute => {
    const route: IAppRoute = { name, path, ...options };
    if (children) {
        route.children = children;
    }

    if (shouldRedirectUnauthorized && route.canActivate === undefined) {
        route.canActivate = unauthorizedRedirectHandler;
    }

    return route;
};

export { createRoutePath, generateSearchParams, makeRoute, unauthorizedRedirectHandler };
