import React, { ReactElement } from 'react';

import { useProjectContext } from 'frontend/projects/use-project';

import { generatePath, useNavigate } from 'react-router-dom';

import { ProjectOverview as BetaProjectOverview } from 'reports/modules/project/components/ProjectOverview';

import { Design } from 'reports/models/design';
import { Project } from 'reports/models/project';

const ProjectOverview = (): ReactElement => {
    const { project } = useProjectContext();

    const navigate = useNavigate();

    const editDesign = (project: Project) =>
        navigate({
            pathname: generatePath('/projects/:projectId/designer/:designId/field_segments', {
                projectId: project.project_id.toString(),
                designId: project.primary_design_id ? project.primary_design_id.toString() : null,
            }),
        });

    const navigateToDesigner = (design: Design) =>
        navigate({
            pathname: generatePath('/projects/:projectId/designer/:designId/field_segments', {
                projectId: design.project_id.toString(),
                designId: design.design_id.toString(),
            }),
        });

    const navigateToFinancials = (project: Project, primaryFinancialConfiguration) =>
        navigate({
            pathname: generatePath('/projects/:projectId/financial-configurations/:finConfigId', {
                projectId: project.project_id.toString(),
                finConfigId: primaryFinancialConfiguration.project_financial_template_id.toString(),
            }),
        });

    return (
        <BetaProjectOverview
            editDesign={editDesign}
            navigateToDesigner={navigateToDesigner}
            navigateToFinancials={navigateToFinancials}
            project={project}
        />
    );
};

export { ProjectOverview };
