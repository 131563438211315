import React from 'react';

import * as DS from '@aurorasolar/ds';

import { getClassicUrl } from 'reports/utils/url';
import ProjectLimitProgressBar from 'reports/components/ProjectLimitProgressBar';
import { useSelector } from 'react-redux';
import * as auth from 'reports/modules/auth';
import * as teamLimitsAndUsage from 'reports/models/team_usage';
import { useLocation, useParams } from 'react-router-dom';

const UserMenu = () => {
    const navigateToClassic = () => {
        window.location.href = getClassicUrl('');
    };

    const user = useSelector((state) => auth.selectors.getUser(state)!);
    const teamUsageAndLimits = user?.team_id
        ? useSelector((state: any) => teamLimitsAndUsage.selectors.byId(state, user?.team_id))
        : undefined;
    const location = useLocation();
    const params = useParams();

    const isUserOnTrial = user?.status === 'trial';
    const shouldShowProgressBar = isUserOnTrial || user.subscription?.subscription_type !== 'contract';

    const showUpsellAtCritical = !!user?.team.should_upsell_consumption;

    const teamLimits = isUserOnTrial ? teamUsageAndLimits?.trial_limits : teamUsageAndLimits?.subscription_limits;

    return (
        <>
            {shouldShowProgressBar && teamLimits && teamLimits?.project_limit && (
                <ProjectLimitProgressBar
                    userOnTrial={isUserOnTrial}
                    projectCount={teamLimits.project_count}
                    projectLimit={teamLimits.project_limit}
                    showUpsellAtCritical={showUpsellAtCritical}
                    routeName={location.pathname}
                    routeParams={params}
                    isTeamAdmin={user.team_admin}
                    planType={user.subscription?.plan_type}
                    condensed={true}
                />
            )}
            <DS.ButtonGroup flexDirection="column" gap={0} ml="-14px" mr="-12px" mt="10px" size="md" variant="tertiary">
                <DS.Button
                    action={navigateToClassic}
                    icon={DS.IconUndoRounded}
                    fullWidth
                    justifyContent="flex-start"
                    maxWidth="none"
                >
                    Use classic mode
                </DS.Button>
            </DS.ButtonGroup>
        </>
    );
};

const ImpersonateTooltip = ({ user, administered }: { user?: string | number | null; administered: boolean }) => {
    // The button here matches the styling for the tooltip button in Borealis. Currently we aren't able to pass
    // in a tooltip action.
    const reloadPage = () => self.document && self.document.location.reload();
    const stopAdministering = () => self.document.location.replace(self.document.location.pathname);
    return (
        <div>
            <DS.Flex flexDirection="column">
                <div>
                    {administered ? 'Administering ' : 'Impersonating '}
                    {user}
                </div>
                <DS.Button
                    action={administered ? stopAdministering : reloadPage}
                    fontWeight={700}
                    h="28px"
                    size="xs"
                    text="body12"
                    variant="tertiary"
                    alignSelf="flex-end"
                >
                    {administered ? 'End Administration' : 'End Impersonation'}
                </DS.Button>
            </DS.Flex>
        </div>
    );
};

const userMenu = (logout: any, administeredUserEmail: string | null, impersonatedUserEmail?: string | number): any => {
    const isAdministering = !!administeredUserEmail;
    const logoutOption = {
        action: logout,
        icon: DS.IconExport,
        iconProps: { transform: 'rotate(90deg)' },
        label: 'Logout',
        value: null,
    };
    const userOverrideOption = {
        icon: DS.IconTeamFill,
        iconProps: { color: 'uiOverrideOption' },
        label: isAdministering ? 'Administering' : 'Impersonating',
        tooltip: (
            <ImpersonateTooltip user={administeredUserEmail || impersonatedUserEmail} administered={isAdministering} />
        ),
        value: null,
    };
    return {
        before: <UserMenu />,
        options: impersonatedUserEmail || administeredUserEmail ? [userOverrideOption, logoutOption] : [logoutOption],
        position: 'navigation-left-rail',
    };
};

export { UserMenu, userMenu };
