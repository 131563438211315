import moment from 'moment';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IAppState } from 'reports/store';

import { IconNames } from '@blueprintjs/icons';

import { CreateButton } from 'reports/components/core/controls';
import { LibraryMain } from 'reports/components/library/main/components';

import * as auth from 'reports/modules/auth';
import { ReportsList } from 'reports/modules/report/components/ReportsList';
import { promptModalBoolean } from 'reports/components/dialog';

import * as proj from 'reports/models/project';
import * as rep from 'reports/models/report';

type Props = {
    inlineControls?: boolean;
    navigateToProjects: () => void;
    navigateToReport: (project: proj.Project, report: rep.Report) => void;
    navigateToReportPreview: (project: proj.Project, report: rep.Report) => void;
    project?: proj.Project;
    preview?: React.ReactNode;
    reportId?: number;
};

const ReportLibrary = ({
    inlineControls,
    navigateToProjects,
    navigateToReport,
    navigateToReportPreview,
    preview,
    project,
    reportId,
}: Props) => {
    const user = useSelector((state: IAppState) => auth.selectors.getUser(state)!);
    const refreshItem = useSelector(
        (state: IAppState) => (item) => rep.selectors.byObject(state, item, { creator: true, team: true }) || item,
    );

    const dispatch = useDispatch();
    const createReport = (name) => dispatch(rep.api.create({ name }));
    const loadItems = (args) => dispatch(rep.api.index(args));

    const openReport = async (report: rep.Report, editing: boolean = false) => {
        if (!project) {
            // For users w/ no projects, show dialog saying "Project must be created first".
            const shouldCreateProj = await promptModalBoolean({
                title: 'Project Required to View Report',
                prompt:
                    "You don't have any projects to preview reports with. Before viewing this report, " +
                    'you must create a project via the "New Project" button on the "All Projects" page. ' +
                    'Would you like to go there now?',
                cancellable: false,
                noLabel: 'Cancel',
                yesLabel: 'Go to "All Projects" page',
            });

            if (shouldCreateProj) {
                navigateToProjects();
            }
            return;
        }
        if (editing) {
            navigateToReport(project, report);
        }
        navigateToReportPreview(project, report);
    };

    const createSimpleReport = async () => {
        const time = moment().format('YYYY-MM-DD hh:mm:ss');
        const name = `${user.full_name}'s Report (${time})`;

        const newReport = await createReport(name);
        return openReport(newReport, true);
    };

    return (
        <LibraryMain
            resourceIdName="report_id"
            loadItems={loadItems}
            refreshItem={refreshItem}
            inlineControls={inlineControls}
            views={[
                {
                    view: <ReportsList openReport={openReport} />,
                    icon: IconNames.LIST,
                    id: 'report-list',
                },
            ]}
            id="reports-views"
            quickFilters={[
                {
                    name: 'bookmarked',
                    icon: IconNames.STAR,
                    text: 'Favorited',
                },
                {
                    name: 'team_id',
                    icon: IconNames.PEOPLE,
                    text: `Only Show My Team`,
                    value: user.team.team_id,
                },
            ]}
            contextBarControls={<CreateButton text="New Report" onClick={() => createSimpleReport()} />}
            preview={preview}
            selectedItemId={reportId}
        />
    );
};

export { ReportLibrary };
