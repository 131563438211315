import React from 'react';

import { Flex, IconLockClosed } from '@aurorasolar/ds';

type Props = {
    locked: boolean;
    title: string;
};

const LockableNavigationItem = ({ locked, title }: Props) => {
    return (
        <Flex flexDirection="row" justifyContent="space-between">
            <span>{title}</span>
            {locked && (
                <span>
                    <IconLockClosed />
                </span>
            )}
        </Flex>
    );
};

export { LockableNavigationItem };
