import React from 'react';

import classNames from 'classnames';

import { Button, Classes, Dialog, Intent, Text } from '@blueprintjs/core';

const SSOCredentialsEnabledSwitchConfirmDialog = ({ ssoProvider, isOpen, onConfirm, onClose }) => {
    const dialogTitle = ssoProvider?.enabled ? 'Disable SSO' : 'Enable SSO';
    const dialogText = ssoProvider?.enabled
        ? 'When you disable SSO, all your team members will be logged out and be required to log in again via username and password. Any third party integrations that use username and password will work again.'
        : 'When you enable SSO, all your team members will be logged out and required to log in again using your SSO provider. Any third party integrations that use username and password will stop working as well.';

    return (
        <Dialog isOpen={isOpen} onClose={onClose} title={dialogTitle}>
            <div className={Classes.DIALOG_BODY}>
                <Text>{dialogText}</Text>
            </div>
            <div className={classNames(Classes.DIALOG_FOOTER, Classes.DIALOG_FOOTER_ACTIONS)}>
                <Button intent={Intent.NONE} onClick={onClose} text="Cancel" />
                <Button intent={Intent.PRIMARY} onClick={onConfirm} text="Confirm" />
            </div>
        </Dialog>
    );
};

export default SSOCredentialsEnabledSwitchConfirmDialog;
