import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IAppState } from 'reports/store';

import { HotkeysProvider } from '@blueprintjs/core';

import { fromNow } from 'reports/utils/formatters';

import { TRANSLATED_LOCALES } from 'reports/localization/lang';

import { EditButton, FavoriteStar } from 'reports/components/core/controls';
import { LibraryPreview } from 'reports/components/core/layout';
import { InlineFormGroup } from 'reports/components/helpers/formHelpers';
import { PreviewDrawer } from 'reports/components/library/main/components';
import { ThemeSelect } from 'reports/modules/themes';

import * as rep from 'reports/models/report';
import * as proj from 'reports/models/project';
import * as tm from 'reports/models/team';
import * as thm from 'reports/models/theme';

import { LocaleSelect } from 'reports/modules/settings/team/components';
import { addPromiseToastsToggle } from 'reports/components/toasts';
import { PrintButton } from 'reports/modules/pdf';
import { ProjectSelect } from 'reports/modules/report/components/ProjectSelect';

import DocumentEditor from './DocumentEditor';

type Props = {
    navigateToReport: (project: proj.Project, report: rep.Report) => void;
    navigateToReportPreview: (project: proj.Project, report: rep.Report) => void;
    navigateToReports: (project: proj.Project) => void;
    printableReady: Promise<any>;
    project: proj.Project;
    report: rep.Report;
};

const ReportPreview = ({
    navigateToReport,
    navigateToReportPreview,
    navigateToReports,
    project,
    printableReady,
    report,
}: Props) => {
    const [drawerOpen, setDrawerOpen] = React.useState<boolean>(false);

    const team: tm.Team = useSelector((state) => proj.selectors.teamSelector(state, project)!);
    const document = useSelector((state: IAppState) => rep.versionSelectors.latestDocument(state, report));
    const theme = useSelector((state) => tm.selectors.themeSelector(state, team));
    const themes = useSelector((state: IAppState) => thm.selectors.all(state));

    const dispatch = useDispatch();
    const openEditor = () => navigateToReport(project, report);
    const setBookmarked = (bookmarked) =>
        dispatch(rep.api.patchTeamSettings({ bookmarked, report_id: report.report_id }));
    const loadThemes = (args) => dispatch(thm.api.index(args));
    const setReportLocale = (report, locale) => dispatch(rep.api.save({ ...report, locale }));
    const setTeamTheme = (team, theme) => dispatch(tm.api.save({ ...team, theme_id: theme.theme_id }));

    React.useEffect(() => {
        // Delay drawer open to allow element to be completely rendered before triggering drawer's transition animation
        setTimeout(() => setDrawerOpen(true), 100);

        loadThemes({});
    }, []);

    const renderEmptyHotkeys = () => <></>; // Disable Report Editor hotkeys for report preview

    const teamConfigControls = (
        <>
            <InlineFormGroup label="Report Locale">
                <LocaleSelect
                    localeList={TRANSLATED_LOCALES}
                    selectedItem={report.locale}
                    onItemSelect={(locale) => setReportLocale(report, locale)}
                    noFill
                />
            </InlineFormGroup>
            <InlineFormGroup label="Team Theme">
                <ThemeSelect
                    themes={themes}
                    selectedTheme={theme}
                    onItemSelect={(theme) => setTeamTheme(team, theme)}
                    disabled={themes.length === 0}
                    noFill
                />
            </InlineFormGroup>
        </>
    );

    const bookmarked = report.bookmarked();
    const lastModified = report.last_modified || report.created;

    return (
        <PreviewDrawer
            isOpen={drawerOpen}
            onClose={() => setDrawerOpen(false)}
            onClosed={() => navigateToReports(project)}
            title={
                <LibraryPreview.Header
                    title={report.name}
                    subtitle={lastModified && `Last modified ${fromNow(lastModified)}`}
                    favorite={
                        <FavoriteStar
                            empty={!bookmarked}
                            onClick={() => {
                                addPromiseToastsToggle(
                                    'favorite',
                                    setBookmarked(!bookmarked),
                                    'report',
                                    report.name,
                                    bookmarked,
                                );
                            }}
                        />
                    }
                />
            }
            editButton={<EditButton onClick={() => openEditor()} />}
            downloadButton={<PrintButton />}
            widthInPercent={70}
            teamConfigControls={teamConfigControls}
        >
            <HotkeysProvider renderDialog={renderEmptyHotkeys}>
                <DocumentEditor
                    mode="view"
                    report={report}
                    document={document}
                    project={project}
                    printableReady={printableReady}
                    controls={
                        <>
                            <ProjectSelect
                                navigateToProjectReport={navigateToReportPreview}
                                report={report}
                                selectedProject={project}
                            />
                            <div style={{ marginRight: 'auto' }} />
                        </>
                    }
                />
            </HotkeysProvider>
        </PreviewDrawer>
    );
};

export { ReportPreview };
