/* tslint:disable:variable-name */

import moment from 'moment';
import { find, sum, sumBy } from 'lodash';

import { ReduxEndpoint, BaseClass, defaults } from 'reports/utils/api';
import request from 'reports/modules/request';

import { Vector } from 'helioscope/app/utilities/geometry';

import { schema } from './schema';
import * as proj from './project';
import * as fs from './field_segment';
import * as wz from './wiring_zone';
import * as fc from './field_component';
import * as sim from './simulation';

import { User } from './user';

const SUNROOF = 'au_sunroof';
const TRIANGLES = 'triangles';

class Design extends BaseClass {
    design_id: number;

    description: string;
    last_modified: moment.Moment;

    project_id: number;
    project: proj.Project;

    to_delete: boolean;
    s3_bucket: string;
    s3_key: string;
    field_component_metadata: IFieldComponentMetadata;

    currently_rendering: boolean;
    render_url?: string;

    shade_keepouts_start: moment.Moment;
    shade_keepouts_end: moment.Moment;

    shade_keepouts: boolean;

    ac_config_id?: number;
    ac_config: any;

    geometry: {
        pcc_location: Vector;
        backup_design_id: number;
        is_backup: boolean;
        version: number;
        tile_layer: 'bing_satellite' | 'google_satellite' | 'google_street' | 'nearmap_satellite';
        lidar_settings: any;
    };

    last_modified_by_user_id?: number;
    last_modified_by_user: User;
    locked: boolean;
    creator_id: number;
    creator: User;
    created: moment.Moment;

    field_segments: fs.FieldSegment[];
    wiring_zones: wz.WiringZone[];
    simulations: sim.Simulation[];

    constructor(data: any) {
        super(Design.deserializer(data));
    }

    get nameplate(): number | undefined {
        return this.field_component_metadata.nameplate;
    }

    get moduleCount(): number {
        return sum(Object.values(this.field_component_metadata.field_segments));
    }

    isComplete(): boolean {
        return !!this.nameplate;
    }

    fieldSegment(id: number) {
        return find(this.field_segments, (fs) => fs.field_segment_id === id);
    }

    wiringZone(id: number) {
        return find(this.wiring_zones, (wz) => wz.wiring_zone_id === id);
    }

    lidarSettings() {
        if (!this.geometry.lidar_settings || !this.geometry.lidar_settings.data_source) {
            this.geometry.lidar_settings = {
                data_source: SUNROOF,
                display_mode: TRIANGLES,
            };
        }
        return this.geometry.lidar_settings;
    }

    canSimulate(nameplateLimit: number | null) {
        return (
            !this.field_component_metadata.metadata_only &&
            (nameplateLimit === null || (this.field_component_metadata.nameplate || 0) <= nameplateLimit)
        );
    }

    static deserializer = BaseClass.getDeserializer({
        last_modified: (x) => moment(x),
        created: (x) => moment(x),
        shade_keepouts_start: (x) => moment(x),
        shade_keepouts_end: (x) => moment(x),
        field_component_metadata: defaults({
            ac_nameplate: 0,
            nameplate: 0,
            field_segments: {},
            wiring_zones: {},
        }),
    });
}

export function deviceCounts(design: Design): { inverter: number; optimizer: number } {
    // For a more granular method of counting devices by device_id, see aggregateDevices in bom.ts
    const counts = { inverter: 0, optimizer: 0 };
    const zones: IWiringZoneMetadata[] = Object.values(design.field_component_metadata.wiring_zones);
    for (const wz of zones) {
        if (wz.inverter != null) {
            counts.inverter += sumBy(wz.inverter, (x) => x.count);
        }
        if (wz.optimizer != null) {
            counts.optimizer += sumBy(wz.optimizer, (x) => x.count);
        }
    }
    return counts;
}

export interface IDesignForm {
    description: string;
    project_id: number;
    clone_design_id?: number;
}

export interface IFieldComponentMetadata {
    ac_nameplate: number;
    nameplate: number;
    field_segments: { [k: number]: number }; // map of field segment id to module count
    wiring_zones: { [k: number]: IWiringZoneMetadata };
    metadata_only: boolean;
}

export interface IWiringZoneMetadata {
    inverter?: IPowerDeviceMetadata[];
    optimizer?: IPowerDeviceMetadata[];
    string?: IWireMetadata[];
    bus?: IWireMetadata[];
    ac_run?: IWireMetadata[];
    ac_branch?: IWireMetadata[];
    combiner?: ICombinerMetadata[];
    ac_panel?: ICombinerMetadata[];
}

interface IPowerDeviceMetadata {
    count: number;
    inverter_count: number;
    manufacturer: string;
    max_power: number;
    name: string;
    power_device_id: number;
}

export interface IWireMetadata {
    count: number;
    length: number;
    wire_gauge: string;
    wire_gauge_id: number;
}

export interface ICombinerMetadata {
    count: number;
    inputs: number;
}

const schemaObj = schema.addObject(Design, 'design');
const endpoint = ReduxEndpoint.fromSchema('/api/designs/', schemaObj);

const { backrefSelector: fieldSegments } = fs.schemaObj.addRelationship('design', schemaObj, {
    backref: 'field_segments',
});

const { backrefSelector: wiringZones } = wz.schemaObj.addRelationship('design', schemaObj, { backref: 'wiring_zones' });

const { backrefSelector: simulations } = sim.schemaObj.addRelationship('design', schemaObj, { backref: 'simulations' });

const api = {
    index: endpoint.index(),
    get: endpoint.get<{ design_id: number | string }>('{design_id}'),
    create: endpoint.post<IDesignForm>(),
    save: endpoint.put('{design_id}'),
    // trigger_render: endpoint.put('{design_id}/trigger-render'),
    trigger_render: endpoint.put<{ design_id: number | string }>(
        '{design_id}/trigger-render',
        ReduxEndpoint.PassThroughConfig(),
    ),
    render: endpoint.get<{ design_id: number | string }, Blob>(
        '{design_id}/render',
        ReduxEndpoint.PassThroughConfig('blob'),
    ),

    render_dxf: endpoint.get<{ design_id: number | string }, { download_url?: string; channel?: string }>(
        '{design_id}/render_dxf',
    ),

    field_components: async (designId: number | string) => {
        const result = await request.get(`/api/field_components/${designId}`);
        return result.body as fc.FieldComponent[];
    },
};

const selectors = {
    fieldSegments,
    wiringZones,
    simulations,

    byId: schemaObj.selectById,
    byObject: schemaObj.selectByObject,
    all: schemaObj.selectAll,
};

export { api, Design, endpoint, schemaObj, selectors };
