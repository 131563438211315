import { Button, ButtonGroup, Classes, Colors, HTMLTable, Menu, Slider } from '@blueprintjs/core';
import classNames from 'classnames';

import Flex from 'reports/components/core/containers/Flex';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

export const ContextMenu = styled(Menu)`
    padding: 0;
    font-size: 12px;
`;

export const ContextMenuHeader = styled.h6.attrs({
    className: Classes.MENU_HEADER,
})`
    padding: 8px 5px;
    margin: 0;
    border-bottom: 1px solid #e4e8ea;
    background: ${Colors.LIGHT_GRAY5};
    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
`;

export const WidgetMenu = styled.div`
    position: absolute;
    background: ${Colors.LIGHT_GRAY3};
    bottom: 100%;
    right: 0%;
    text-align: right;
    border-radius: 8px 8px 0 0;
`;

export const MenuButton = styled(Button)`
    &.widget-menu-icon::before {
        color: ${Colors.DARK_GRAY5};
    }
`;

// Widget action bar (save/cancel) - currently unused with autosaving
export const ActionBar = styled(ButtonGroup)`
    position: absolute;
    background: ${Colors.LIGHT_GRAY3};
    top: 100%;
    right: 0%;
    border-radius: 0 0 8px 8px;
`;

export const ButtonBar = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const FormatButton = styled(Button).attrs((props) => ({
    className: classNames(props.className, Classes.MINIMAL),
}))`
    margin: 2px;
`;

export const FormSection = styled(Flex.ContainerV)`
    padding: 6px 0px;

    & > div {
        padding-bottom: 6px;
    }
`;

export const FormHeader = styled.div`
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 6px;
    padding: 6px 0;
    color: ${Colors.GRAY1};
    border-bottom: 1px solid ${Colors.GRAY1};
`;

// TODO: type system jank
export const FormSlider: Slider = styled(Slider as any)`
    width: calc(100% - 20px);
    margin: 0 10px;
` as any;

export const PanelColumn = styled(Flex.ContainerV)`
    background-color: #f0f0f0;
    border-left: 1px solid #e2e2e2;
    width: 320px;
    overflow: hidden;
`;

export const PanelColumnBody = styled(Flex.ContainerV)`
    overflow: auto;
`;

export const PanelHeader = styled(Flex.Container).attrs({
    className: 'center',
})`
    justify-content: space-between;
    height: 42px;
    min-height: 42px;

    font-size: 14px;
    font-weight: 400;
    margin-bottom: 2px;
    padding: 6px 12px;
    color: #404040;
    background: #e4e4e4;
    text-transform: capitalize;

    &:hover {
        cursor: pointer;
    }
`;

export const PanelBody = styled.div`
    margin: 0px 12px 12px;

    // TODO: remove this
    & > div {
        display: flex;

        & > div {
            flex: 1;
        }
    }
    & > div.block {
        display: block;
    }
`;

export const TooltipContent = styled.div`
    font-size: 12px;
    max-width: 150px;
`;

// Table styles
const WIDGET_BORDER_RADIUS = 5;
const OUTER_BORDER_COLOR = Colors.GRAY3;
const INNER_BORDER_COLOR = Colors.GRAY4;
const STRIPE_BACKGROUND_COLOR = Colors.LIGHT_GRAY4;
const HEADER_BACKGROUND_COLOR = Colors.LIGHT_GRAY2;
const ENERGY_SUMMARY_BACKGROUND_COLOR = '#FFE0B2';
const FINANCIAL_SUMMARY_BACKGROUND_COLOR = Colors.LIGHT_GRAY3;

export const DetailsTable = styled.table.attrs({
    className: 'table table-horizontal',
})``;

const WidgetBaseTable = styled(HTMLTable).attrs((props) => ({
    bordered: true,
    condensed: true,
    interactive: props.interactive !== undefined ? props.interactive : true,
}))`
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-collapse: collapse;

    &.${Classes.HTML_TABLE} th,
    &.${Classes.HTML_TABLE} td {
        text-align: left;
        vertical-align: middle;
    }
    th {
        background-color: ${HEADER_BACKGROUND_COLOR};
    }

    // Border styles
    border: 1px solid ${OUTER_BORDER_COLOR};
    tr:first-child > th,
    tr:first-child > td {
        border-top: 1px solid ${OUTER_BORDER_COLOR};
    }
    tbody > tr:last-child > td,
    tbody > tr:last-child > th {
        border-bottom: 1px solid ${OUTER_BORDER_COLOR};
    }
    tr > :first-child {
        border-left: 1px solid ${OUTER_BORDER_COLOR};
    }
    tr > :last-child {
        border-right: 1px solid ${OUTER_BORDER_COLOR};
    }
    tbody > tr > th:last-of-type {
        border-bottom: 1px solid ${OUTER_BORDER_COLOR};
    }
    th {
        border: 1px solid ${INNER_BORDER_COLOR};
    }

    // Override styles for widgets with headers
    &.widget-header {
        border: none;

        // Border radii
        border-radius: ${WIDGET_BORDER_RADIUS}px;
        & > tr:last-child > :first-child {
            border-bottom-left-radius: ${WIDGET_BORDER_RADIUS}px;
        }
        & > tr:last-child > :last-child {
            border-bottom-right-radius: ${WIDGET_BORDER_RADIUS}px;
        }
        td.table-cell {
            table {
                border-bottom: none;
            }
            table,
            table tbody > tr:last-child > :last-child {
                border-bottom-right-radius: ${WIDGET_BORDER_RADIUS}px;
            }
        }

        // Borders
        && tr:first-child > th {
            box-shadow: none;
        }
        && tr:first-child > td {
            box-shadow: inset 1px 0 0 0 rgba(16, 22, 26, 0.15);
        }
        tr:first-child > * {
            border-top: none;
        }
        tr > :first-child {
            border-left: none;
        }
        tr > :last-child {
            border-right: none;
        }
        tbody > tr > :last-child {
            &.table-cell table {
                border-right: none;
            }
        }
        tbody > tr:last-child > * {
            border-bottom: none;
        }
    }

    tr.header-row {
        border-top: 1px solid ${INNER_BORDER_COLOR};
    }

    tr.energy-summary {
        background-color: ${ENERGY_SUMMARY_BACKGROUND_COLOR};
        font-size: 105%;
        font-weight: bold;
    }

    tr.summary {
        background-color: ${FINANCIAL_SUMMARY_BACKGROUND_COLOR};
        font-weight: bold;
    }

    td.table-cell {
        padding: 0px !important;
        margin: 0;
        text-align: inherit;

        table {
            border: 1px solid ${INNER_BORDER_COLOR};
        }
    }

    td.hidden-cell {
        padding: 0;
    }

    && td.centered {
        vertical-align: middle;
        text-align: center;
    }

    &&& td.footer {
        text-align: right;
        font-size: 80%;
    }

    tr.subrow {
        td {
            color: ${Colors.GRAY3};
        }

        td.first-child {
            padding-left: 10px;
        }
    }
`;

export const WidgetDataTable = styled(WidgetBaseTable)<{
    fontSizePixel?: number;
}>`
    &.${Classes.HTML_TABLE} {
        ${(props) => (props.fontSizePixel ? `font-size: ${props.fontSizePixel}px;` : '')}
    }

    tbody tr:nth-child(even) {
        background-color: ${STRIPE_BACKGROUND_COLOR};
    }
    &&.${Classes.HTML_TABLE} th,
    &&.${Classes.HTML_TABLE} td {
        text-align: left;
    }

    &&.align-right {
        td {
            text-align: right;
        }
    }
`;

export const WidgetDetailsTable = styled(WidgetBaseTable)`
    &&.${Classes.HTML_TABLE} th,
    &&.${Classes.HTML_TABLE} td {
        text-align: left;
    }
`;
