import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ContextBarControls } from 'reports/components/ContextBar';
import SaveDropdown from 'reports/components/library/SaveDropdown';
import UndoRedoManager from 'reports/components/library/UndoRedoManager';

import * as proj from 'reports/models/project';
import * as rep from 'reports/models/report';
import * as repCfg from 'reports/models/report/ReportConfiguration';

import { addPromiseToasts } from 'reports/modules/Toaster';

import { IAppState } from 'reports/store';

import { RouterStateLock } from 'reports/utils/router';

import DocumentEditor from '../components/DocumentEditor';
import { ReportUndoRedoButtons } from '../components/ReportUndoRedoButtons';
import { UpdateReportConfigControls } from '../components/UpdateReportConfigControls';
import { ViewReportTemplateButton } from '../components/ViewReportTemplateButton';
import { HeaderContainer } from 'reports/modules/report/components/ReportEditor';
import { ButtonGroup } from '@blueprintjs/core';

type Props = {
    inlineControls?: boolean;
    navigateToReportView: (project: proj.Project, report: rep.Report) => void;
    printableReady: Promise<any>;
    project: proj.Project;
    report: rep.Report;
};

interface IUndoRedoData {
    document: rep.Document;
}

const ReportConfigurationEditor = ({
    inlineControls,
    navigateToReportView,
    printableReady,
    project,
    report,
}: Props) => {
    const reportConfig = useSelector(
        (state: IAppState) => rep.repCfgSelectors.configForProject(state, report, project.project_id)!,
    );

    const dispatch = useDispatch();
    const saveRepConfig = ({ report_configuration_id, configuration_data }) =>
        dispatch(repCfg.api.save({ report_configuration_id, configuration_data }));
    const { report: configReport, report_configuration_id } = reportConfig;
    const baseDocument = React.useMemo<rep.Document>(() => reportConfig.makeDocument(), [reportConfig]);

    return (
        <UndoRedoManager<IUndoRedoData>
            baseData={{ document: baseDocument }}
            hasChangesFrom={(oldData, newData) => newData.document.hasChangesFrom(oldData.document)}
            hotkeyGroup="Configure Report"
        >
            {({ data: { document }, setData, undo, redo, hasChanges, canUndo, canRedo, clearChanges }) => {
                const save = async () => {
                    const newRepCfg = await saveRepConfig({
                        report_configuration_id,
                        configuration_data: rep.ReportConfiguration.makeConfigData(document),
                    });
                    await clearChanges();
                    return newRepCfg;
                };

                const saveWithToast = () =>
                    addPromiseToasts(save(), {
                        initial: `Saving ${configReport.name} configuration`,
                        onCatch: `Error saving ${configReport.name} configuration`,
                        onSuccess: `Successfully saved ${configReport.name} configuration`,
                    });
                const setDocument = (newDoc: rep.Document) => setData({ document: newDoc });

                const controls = (
                    <>
                        <ButtonGroup>
                            <ViewReportTemplateButton project={project} report={configReport} />
                        </ButtonGroup>
                        <ReportUndoRedoButtons undo={undo} canUndo={canUndo()} redo={redo} canRedo={canRedo()} />
                        <SaveDropdown
                            saveEnabled={hasChanges()}
                            save={() => {
                                // Don't use saveWithToast() here. SaveDropdown does its own toast handling.
                                return save().then(() => navigateToReportView(project, configReport));
                            }}
                            cancel={async () => {
                                await clearChanges();
                                navigateToReportView(project, configReport);
                            }}
                        />
                    </>
                );

                return (
                    <>
                        <RouterStateLock
                            title="Save configuration changes?"
                            prompt={
                                `You have unsaved changes on the configuration of ${configReport.name}` +
                                `. Would you like to save these changes before leaving?`
                            }
                            cancellable={true}
                            showExitPrompt={hasChanges}
                            onSave={() => saveWithToast()}
                            onDontSave={clearChanges}
                        />
                        {inlineControls ? (
                            <HeaderContainer>
                                <div>{report.name}</div>
                                <div style={{ marginLeft: 'auto' }}>{controls}</div>
                            </HeaderContainer>
                        ) : (
                            <ContextBarControls>{controls}</ContextBarControls>
                        )}
                        <DocumentEditor
                            mode="configure"
                            report={configReport}
                            document={document}
                            setDocument={setDocument}
                            project={project}
                            printableReady={printableReady}
                            controls={<UpdateReportConfigControls reportConfig={reportConfig} />}
                        />
                    </>
                );
            }}
        </UndoRedoManager>
    );
};

export { ReportConfigurationEditor };
