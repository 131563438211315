import React from 'react';

import classNames from 'classnames';

import { Button, Classes, Dialog, Intent, Text } from '@blueprintjs/core';

const SSOCredentialsConfirmDialog = ({ isOpen, onConfirm, onClose, loading }) => (
    <Dialog isOpen={isOpen} onClose={onClose} title="Single-sign on">
        <div className={Classes.DIALOG_BODY}>
            <Text>
                When you remove SSO, all users including your current session will be logged out and required to set new
                passwords to access HelioScope.
            </Text>
        </div>
        <div className={classNames(Classes.DIALOG_FOOTER, Classes.DIALOG_FOOTER_ACTIONS)}>
            <Button intent={Intent.NONE} onClick={onClose} text="Cancel" />
            <Button intent={Intent.PRIMARY} onClick={onConfirm} text="Confirm" disabled={loading} loading={loading} />
        </div>
    </Dialog>
);

export default SSOCredentialsConfirmDialog;
