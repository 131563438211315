import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from 'reports/store';

import { ButtonGroup } from '@blueprintjs/core';

import { PrimaryIntent } from 'reports/components/core/controls';
import LibraryEditor from 'reports/components/core/layout/LibraryEditor';
import { promptModalBoolean } from 'reports/components/dialog';
import SaveDropdown from 'reports/components/library/SaveDropdown';
import UndoRedoManager from 'reports/components/library/UndoRedoManager';
import { ContextBarControls } from 'reports/components/ContextBar';
import { EditableTitleSubHeader } from 'reports/components/helpers/common';

import * as proj from 'reports/models/project';
import * as rep from 'reports/models/report';
import * as repCfg from 'reports/models/report/ReportConfiguration';

import { ProjectSelect } from 'reports/modules/report/components/ProjectSelect';
import * as auth from 'reports/modules/auth';
import { canModifySharedTeamResource } from 'reports/modules/auth/permissions';
import { actions } from 'reports/modules/report';
import { addPromiseToasts } from 'reports/modules/Toaster';
import { PrintButton } from 'reports/modules/pdf';

import { RouterStateLock } from 'reports/utils/router';

import DocumentEditor from './DocumentEditor';
import { ReportPageSettingControls } from './ReportPageSettingsControls';
import { ReportUndoRedoButtons } from './ReportUndoRedoButtons';

import * as styles from 'reports/styles/styled-components';
const styled = styles.styled;

const StyledDocumentEditor = styled(DocumentEditor)`
    // 58px is the height of EditableHeader. Subtract that to make sure no extra scrollbar is introduced.
    height: calc(100% - 58px);
`;

const HeaderContainer = styled.div`
    display: flex;
    flex-direction: row;
    padding: 10px;
    align-items: center;
    box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.42); // Taken from the styles used for the beta report editor header.
`;

const EditableHeader = ({
    inlineControls,
    name,
    onUpdate,
}: {
    inlineControls?: React.ReactNode;
    name: string;
    onUpdate: (val: string) => any;
}) =>
    inlineControls ? (
        <HeaderContainer>
            <EditableTitleSubHeader value={name} updateFn={onUpdate} />
            <div style={{ marginLeft: 'auto' }}>{inlineControls}</div>
        </HeaderContainer>
    ) : (
        <div className="content-header">
            <EditableTitleSubHeader value={name} updateFn={onUpdate} />
        </div>
    );

type Props = {
    inlineControls?: boolean;
    navigateToReport: (project: proj.Project, report: rep.Report) => void;
    navigateToReportPreview: (project: proj.Project, report: rep.Report) => void;
    printableReady: Promise<any>;
    project: proj.Project;
    report: rep.Report;
};

interface IUndoRedoData {
    report: rep.Report;
    document: rep.Document;
}

const ReportEditor = ({
    inlineControls,
    navigateToReport,
    navigateToReportPreview,
    printableReady,
    project,
    report,
}: Props) => {
    const user = useSelector((state: IAppState) => auth.selectors.getUser(state)!);
    const document = useSelector((state: IAppState) => rep.versionSelectors.latestDocument(state, report));

    const dispatch = useDispatch();
    const saveReport = (formData) => dispatch(rep.api.save(formData));
    const saveAsNew = (document) => dispatch(actions.saveAsNew(report, document));
    const reportConfigsCount = () => dispatch(repCfg.api.configsCount({ report_id: report.report_id }));
    return (
        <LibraryEditor.Container style={{ padding: 0 }}>
            <UndoRedoManager<IUndoRedoData>
                baseData={{ report, document }}
                hasChangesFrom={(oldData, newData) =>
                    newData.document.hasChangesFrom(oldData.document) || newData.report.hasChangesFrom(oldData.report)
                }
                hotkeyGroup="Report Editor"
            >
                {({ data: { document, report }, setData, undo, redo, hasChanges, canUndo, canRedo, clearChanges }) => {
                    const save = async () => {
                        const configsCount = (await reportConfigsCount()).count;
                        if (configsCount > 0) {
                            await promptModalBoolean({
                                title: 'Update report with existing configurations?',
                                prompt: (
                                    <p>
                                        This report template currently has {configsCount} project{' '}
                                        {configsCount === 1 ? 'configuration' : 'configurations'}
                                        . Updating this template may override an existing configuration if any
                                        configured widgets have been removed.
                                        <br />
                                        <br />
                                        Would you like to save this report anyway?
                                    </p>
                                ),
                                cancellable: true,
                                yesLabel: 'Save',
                                yesIntent: PrimaryIntent.SAVE,
                                dialogProps: {
                                    // needed so title text doesn't overflow
                                    style: { width: 500 },
                                },
                            });
                        }
                        const newRep: rep.Report = await saveReport({
                            ...report,
                            document,
                        });
                        await clearChanges();
                        return newRep;
                    };

                    const saveWithToast = () =>
                        addPromiseToasts(save(), {
                            initial: `Saving ${report.name}`,
                            onCatch: `Error saving ${report.name}`,
                            onSuccess: `Successfully saved ${report.name}`,
                        });
                    const setDocument = (newDoc: rep.Document) => setData({ report, document: newDoc });
                    const setReport = (newRep: rep.Report) => setData({ document, report: newRep });

                    const controls = (
                        <>
                            <ButtonGroup>
                                <PrintButton />
                            </ButtonGroup>
                            <ReportUndoRedoButtons undo={undo} canUndo={canUndo()} redo={redo} canRedo={canRedo()} />
                            <SaveDropdown
                                saveEnabled={hasChanges() && canModifySharedTeamResource(user, report)}
                                save={() => {
                                    // Don't use saveWithToast() here. SaveDropdown does its own toast handling.
                                    return save().then(() => navigateToReportPreview(project, report));
                                }}
                                cancel={async () => {
                                    await clearChanges();
                                    navigateToReportPreview(project, report);
                                }}
                                saveAsNewOptions={{
                                    text: 'Save as New Report',
                                    saveAsNew: async () => {
                                        // There's no need to show a toast here. SaveDropdown does its own toast
                                        // handling.
                                        const newReport = await saveAsNew(document);
                                        await clearChanges();
                                        navigateToReportPreview(project, newReport);
                                    },
                                }}
                            />
                        </>
                    );
                    return (
                        <>
                            <RouterStateLock
                                title="Save report changes?"
                                prompt={
                                    `You have unsaved changes on ${report.name || 'this proposal'}` +
                                    `, would you like to save these changes before leaving?`
                                }
                                cancellable={true}
                                showExitPrompt={hasChanges}
                                onSave={() => saveWithToast()}
                                onDontSave={clearChanges}
                            />
                            {!inlineControls && <ContextBarControls>{controls}</ContextBarControls>}
                            <EditableHeader
                                name={report.name}
                                onUpdate={(name) => setReport(new rep.Report({ ...report, name }))}
                                inlineControls={inlineControls ? controls : null}
                            />
                            <StyledDocumentEditor
                                mode="edit"
                                report={report}
                                document={document}
                                setDocument={setDocument}
                                project={project}
                                printableReady={printableReady}
                                controls={
                                    <>
                                        <ProjectSelect
                                            navigateToProjectReport={navigateToReport}
                                            report={report}
                                            selectedProject={project}
                                        />
                                        <div style={{ marginRight: 'auto' }} />
                                        <ReportPageSettingControls document={document} onUpdate={setDocument} />
                                    </>
                                }
                            />
                        </>
                    );
                }}
            </UndoRedoManager>
        </LibraryEditor.Container>
    );
};

export { HeaderContainer, ReportEditor };
