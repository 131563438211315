import * as React from 'react';

import * as rep from 'reports/models/report';
import * as proj from 'reports/models/project';

import { ProjectSelectDropdownContainer } from 'reports/components/CustomDropdowns';
import { InlineFormGroup } from 'reports/components/helpers/formHelpers';

type Props = {
    navigateToProjectReport: (project: proj.Project, report: rep.Report) => void;
    report: rep.Report;
    selectedProject: proj.Project;
};

const ProjectSelect = ({ navigateToProjectReport, report, selectedProject }: Props) => (
    <InlineFormGroup label="Project">
        <ProjectSelectDropdownContainer
            text={selectedProject.name}
            onItemSelect={(project) => navigateToProjectReport(project, report)}
        />
    </InlineFormGroup>
);

export { ProjectSelect };
