import { debounce } from 'lodash';
import moment from 'moment';

import * as React from 'react';
import { connect, useDispatch } from 'react-redux';
import { actions as routerActions } from 'redux-router5';

import { Button, Classes as bpClasses } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { IAppState } from 'reports/types';
import { humanizeTimestamp } from 'reports/utils/formatters';
import { bindActions } from 'reports/utils/redux';
import { DeepPartial } from 'reports/utils/types';

import DataTable, { ColWidth, SortOrder, SortType, TableSortConfig } from 'reports/components/core/tables/DataTable';
import { ContextBarControls } from 'reports/components/ContextBar';
import { FavoriteStar, Tooltip } from 'reports/components/core/controls';

import { api, IProjectAPIQueryOpts, Project, saver } from 'reports/models/project';

import { selectors as authSelectors } from 'reports/modules/auth';
import ProjectSearch from 'reports/modules/project/components/ProjectSearch';
import SaveDialog from 'reports/modules/report/components/SaveDialog';
import { addPromiseToastsToggle } from 'reports/components/toasts';

import * as styles from 'reports/styles/styled-components';
import { Box } from '@aurorasolar/ds';
import Colors from 'reports/styles/colors';
import { Price } from 'reports/models/stripe';
import { useRoute } from 'react-router5';
import { ReferrerTypes } from 'reports/analytics/ReferrerTypes';
const styled = styles.styled;

const ArchiveButton = ({ active, onClick }) => (
    <Tooltip content={active ? 'Unarchive project' : 'Archive project (removes it from this list)'}>
        <Button
            icon={IconNames.BOX}
            active={active}
            onClick={onClick}
            className={active ? bpClasses.INTENT_PRIMARY : ''}
        />
    </Tooltip>
);

const ProjectListContainer = styled.div`
    position: relative;
    top: 0;
    height: 100%;
    width: 100%;
`;

const getProjectDate = (project: Project) => project.last_modified || project.created;

interface IDispatchProps {
    getProjects: (queryOpts: IProjectAPIQueryOpts) => Promise<Project[]>;
    saveProject: (project: Project, patch: DeepPartial<Project>) => Promise<Project>;
}

interface IOwnProps {
    inlineControls?: boolean;
    navigateToProject: (projectId: number) => void;
    openAddProjectsDialog: () => void;
    redirectToCheckoutFlow: (interval: string, price: Price) => void;
}

interface IState {
    archivePrompt: JSX.Element | null;
    tableSortConfig?: TableSortConfig<Project>;
    filterArchived: boolean;
    filterShared: boolean;
    projects: Promise<Project[]>;
    searching: boolean;
    searchValue: string;
}

type IStateProps = ReturnType<ReturnType<typeof mapStateToProps>>;
export type IToastMsgs = { onSuccess: string; onError: string };

// Injects react-router5 routing methods into ProjectList, should only be used in Beta
const ProjectListBare = ({ navigateToProject }) => {
    const { route } = useRoute();
    const dispatch = useDispatch();
    const openAddProjectsDialog = () =>
        dispatch(routerActions.navigateTo(route.name, { ...route.params, dialog: 'add_projects' }));
    const redirectToCheckoutFlow = (interval: string, price: Price) => {
        return dispatch(
            routerActions.navigateTo('app.settings.team.billing', {
                interval,
                price,
                dialog: 'initial',
                referrer: ReferrerTypes.expired_account_dialog,
            }),
        );
    };
    return (
        <ProjectList
            openAddProjectsDialog={openAddProjectsDialog}
            navigateToProject={navigateToProject}
            redirectToCheckoutFlow={redirectToCheckoutFlow}
        />
    );
};

class _ProjectList extends React.Component<IStateProps & IDispatchProps & IOwnProps, IState> {
    state: IState = {
        projects: Promise.resolve([]),

        // Project list filtering
        archivePrompt: null,
        filterArchived: true,
        filterShared: false,
        searching: false,
        searchValue: '',
    };

    async componentDidMount() {
        await this.getProjects();
    }
    render() {
        const { inlineControls, navigateToProject, openAddProjectsDialog, redirectToCheckoutFlow } = this.props;
        return (
            <ProjectListContainer>
                {!inlineControls ? (
                    <ContextBarControls>
                        <ProjectSearch
                            handleInputChange={this.handleSearchInputChange}
                            searchValue={this.state.searchValue}
                            searching={this.state.searching}
                            toggleFilter={this.toggleFilter}
                            filterArchived={this.state.filterArchived}
                            filterShared={this.state.filterShared}
                            inlineControls={inlineControls}
                            navigateToProject={navigateToProject}
                            openAddProjectsDialog={openAddProjectsDialog}
                            redirectToCheckoutFlow={redirectToCheckoutFlow}
                        />
                    </ContextBarControls>
                ) : (
                    <ProjectSearch
                        handleInputChange={this.handleSearchInputChange}
                        searchValue={this.state.searchValue}
                        searching={this.state.searching}
                        toggleFilter={this.toggleFilter}
                        filterArchived={this.state.filterArchived}
                        filterShared={this.state.filterShared}
                        inlineControls={inlineControls}
                        navigateToProject={navigateToProject}
                        openAddProjectsDialog={openAddProjectsDialog}
                        redirectToCheckoutFlow={redirectToCheckoutFlow}
                    />
                )}
                <Box h="100%" overflowY="auto" border={inlineControls && `1px solid ${Colors.BORDER_TABLE_LIGHT}`}>
                    <DataTable
                        items={this.state.projects}
                        centered={true}
                        hasScrollContainer={inlineControls}
                        sticky={true}
                        width="100%"
                        onRefresh={() => this.getProjects()}
                        onSortChange={(tableSortConfig) => {
                            this.setState({ tableSortConfig });
                            this.getProjects(tableSortConfig);
                        }}
                        rowSelectConfig={{
                            onRowClick: (project) => this.props.navigateToProject(project.project_id),
                            itemSelected: () => false,
                        }}
                        columns={[
                            {
                                colWidth: ColWidth.SMALL,
                                headerText: 'Favorite',
                                renderCell: (project) => (
                                    <>
                                        <Tooltip
                                            content={
                                                <span style={{ fontSize: 12 }}>
                                                    Favorite projects will appear first
                                                </span>
                                            }
                                        >
                                            <FavoriteStar
                                                empty={!project.favorited}
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    addPromiseToastsToggle(
                                                        'favorite',
                                                        this.props.saveProject(project, {
                                                            favorited: !project.favorited,
                                                        }),
                                                        'project',
                                                        project.name,
                                                        project.favorited,
                                                        (updated) => this.postUpdate(project, updated),
                                                    );
                                                }}
                                            />
                                        </Tooltip>
                                    </>
                                ),
                            },
                            {
                                sort: { name: 'name', type: SortType.ALPHA },
                                colWidth: ColWidth.LARGE,
                                headerText: 'Project',
                                renderCell: (project) => (
                                    <div style={{ width: '100%', textAlign: 'left' }}>
                                        <div
                                            style={{
                                                fontSize: 14,
                                                fontWeight: 600,
                                            }}
                                        >
                                            {project.name}
                                        </div>
                                        {project.description ? <div>{project.description}</div> : null}
                                        <div>{project.address}</div>
                                    </div>
                                ),
                            },
                            {
                                sort: {
                                    name: 'last_modified',
                                    type: SortType.OTHER,
                                },
                                colWidth: ColWidth.MEDIUM,
                                headerText: 'Last Modified',
                                renderCell: (project) => {
                                    const projectDate = getProjectDate(project);
                                    const longFormDate = moment(projectDate).format('LLLL');
                                    return <Tooltip content={longFormDate}>{humanizeTimestamp(projectDate)}</Tooltip>;
                                },
                            },
                            {
                                colWidth: ColWidth.MEDIUM,
                                headerText: 'User',
                                renderCell: (project) => <>{project.creator.fullName()}</>,
                            },
                            {
                                colWidth: ColWidth.MEDIUM,
                                headerText: 'Size',
                                renderCell: (project) => <>{project.sizeRange}</>,
                            },
                            {
                                colWidth: ColWidth.SMALL,
                                headerText: 'Designs',
                                renderCell: (project) => <>{project.metadata.designs}</>,
                            },
                            {
                                colWidth: ColWidth.SMALL,
                                headerText: 'Simulations',
                                renderCell: (project) => <>{project.metadata.simulations}</>,
                            },
                            {
                                colWidth: ColWidth.SMALL,
                                headerText: 'Users',
                                renderCell: (project) => <>{project.metadata.users}</>,
                            },
                            {
                                colWidth: ColWidth.SMALL,
                                headerText: 'Archive',
                                renderCell: (project) => (
                                    <>
                                        <ArchiveButton
                                            active={project.archived}
                                            onClick={(e) => this.promptArchive(e, project)}
                                        />
                                    </>
                                ),
                            },
                        ]}
                    />
                </Box>
                {this.state.archivePrompt}
            </ProjectListContainer>
        );
    }

    getProjects = async (tableSortConfig = this.state.tableSortConfig) => {
        const { user } = this.props;

        const queryOpts: IProjectAPIQueryOpts = {
            q: this.state.searchValue,
            include_archived: !this.state.filterArchived,

            // Build sort order
            ...(tableSortConfig
                ? {
                      sort: `${tableSortConfig.property} ${tableSortConfig.order === SortOrder.DESC ? 'desc' : 'asc'}`,
                  }
                : {}),

            // Filter by creator if filterShared is on
            ...(user && this.state.filterShared ? { creator_id: user.user_id } : {}),
        };
        const projects = this.props.getProjects(queryOpts);
        this.setState({ projects });

        await projects;
    };

    handleSearchInputChange = (e: React.FormEvent<HTMLElement>) => {
        this.setState(
            {
                searching: true,
                searchValue: (e.target as HTMLInputElement).value,
            },
            this.searchProjects,
        );
    };

    promptArchive = (e: React.SyntheticEvent, project: Project) => {
        e.stopPropagation();
        const archivePrompt = (
            <SaveDialog
                title={project.archived ? 'Remove from archives' : 'Archive project'}
                isOpen={true}
                cancel={() => this.setState({ archivePrompt: null })}
                saveChanges={() => {
                    addPromiseToastsToggle(
                        'archive',
                        this.props.saveProject(project, {
                            archived: !project.archived,
                        }),
                        'project',
                        project.name,
                        project.archived,
                        (updated) => this.postUpdate(project, updated),
                    );
                    this.setState({ archivePrompt: null });
                }}
                content={
                    project.archived
                        ? `Are you sure you want to remove ${project.name} from the archives?`
                        : `Are you sure you want to archive ${project.name}?`
                }
                primaryButtonText="Yes"
                secondaryButtonText="No"
            />
        );
        this.setState({ archivePrompt });
    };

    searchProjects = debounce(() => this.getProjects().finally(() => this.setState({ searching: false })), 200);

    toggleFilter = (filter: string) => this.setState({ [filter]: !this.state[filter] } as any, this.getProjects);

    postUpdate = (project, updatedProject) => {
        const projects = this.state.projects.then((projects) =>
            projects.slice().map((proj) => (proj.project_id === project.project_id ? updatedProject : proj)),
        );
        this.setState({ projects });
    };
}

const mapStateToProps = () => {
    const { getUser } = authSelectors;

    return (state: IAppState) => ({
        user: getUser(state),
    });
};

const mapDispatchToProps = bindActions({
    getProjects: api.index,
    saveProject: (proj, data) => saver.get(proj).patch(data, true),
});

const ProjectList = connect(mapStateToProps, mapDispatchToProps)(_ProjectList);

export { ProjectList, ProjectListBare };
