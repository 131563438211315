import { Intent } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { isEmpty } from 'lodash';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'reports/components/core/controls';
import Callout from 'reports/components/core/controls/Callout';

import * as rep from 'reports/models/report';
import * as repCfg from 'reports/models/report/ReportConfiguration';

import { IAppState } from 'reports/store';

type Props = {
    reportConfig: repCfg.ReportConfiguration;
};

const UpdateReportConfigControls = ({ reportConfig }: Props) => {
    const latestVersion = useSelector(
        (state: IAppState) => rep.versionSelectors.latestVersion(state, reportConfig.report)!,
    );
    const dispatch = useDispatch();
    const saveReportConfig = ({ report_configuration_id, report_version_id, configuration_data }) =>
        dispatch(
            repCfg.api.save({
                report_configuration_id,
                report_version_id,
                configuration_data,
            }),
        );
    const deleteReportConfig = ({ report_configuration_id }) =>
        dispatch(repCfg.api.delete({ report_configuration_id }));

    if (reportConfig.report_version_id === latestVersion.report_version_id) {
        return null;
    }

    const updateConfigVersion = () => {
        if (reportConfig == null) return;

        const { report_configuration_id } = reportConfig;
        const newConfigData = reportConfig.removeStaleWidgets(latestVersion);
        if (isEmpty(newConfigData.widgets)) {
            return deleteReportConfig({ report_configuration_id });
        }

        return saveReportConfig({
            report_configuration_id,
            report_version_id: latestVersion.report_version_id,
            configuration_data: newConfigData,
        });
    };

    return (
        <Callout
            intent={Intent.WARNING}
            rightElement={
                <Button
                    icon={IconNames.REFRESH}
                    onClick={updateConfigVersion}
                    text="Update Report"
                    style={{ minWidth: '135px' }}
                />
            }
        >
            <span>
                An updated template is available for this report. Update to get the newest changes. Any configurations
                for widgets that have been removed from the report template will be lost.
            </span>
        </Callout>
    );
};

export { UpdateReportConfigControls };
