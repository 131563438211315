import React from 'react';
import { generatePath, useNavigate, useOutletContext } from 'react-router-dom';

import { ProjectReportContext } from 'frontend/projects/project-report-base';
import { FullWidthHeight } from 'frontend/layout/common/full-width-height';

import { Report } from 'reports/models/report';
import { Project } from 'reports/models/project';

import { ReportConfigurationEditor } from 'reports/modules/report/views/ReportConfigurationEditor';

const ProjectReportConfigEditor = () => {
    const { printableReady, project, report } = useOutletContext() as ProjectReportContext;

    if (!report) {
        throw new Response('Not Found', { status: 404 });
    }

    const navigate = useNavigate();
    const navigateToReportView = (project: Project, report: Report) => {
        const routePath = generatePath('/projects/:projectId/report/:slug', {
            projectId: String(project.project_id),
            slug: report.slug,
        });
        navigate(routePath);
    };

    return (
        <FullWidthHeight>
            <ReportConfigurationEditor
                inlineControls={true}
                navigateToReportView={navigateToReportView}
                printableReady={printableReady}
                project={project}
                report={report}
            />
        </FullWidthHeight>
    );
};

export { ProjectReportConfigEditor };
