import * as React from 'react';
import { useDispatch } from 'react-redux';

import classNames from 'classnames';

import { Button, Classes, Dialog, Intent } from '@blueprintjs/core';

import { Form } from 'reports/components/forms';
import { FormErrorCallout } from 'reports/components/helpers/errors';
import { api as externalCredentialsApi } from 'reports/models/external_credentials';

import { styled } from 'reports/styles/styled-components';
import FormPasswordInput from 'reports/components/forms/inputs/experimental/FormPasswordInput';
import { Toaster } from 'reports/modules/Toaster';
import { IntegrationCardState } from './IntegrationCard';
import { IntegrationOption } from 'reports/modules/settings/team/components/IntegrationCardOverview';

interface IExternalCredentialsDialogProps {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    cardState: IntegrationCardState;
    setCardState: (state: IntegrationCardState) => void;
    options: IntegrationOption;
}

interface FormData {
    api_key: string;
    connect: boolean;
}

const DialogContent = styled.div`
    margin: 0px 0px 24px 0px;
    & p {
        margin: 0;
    }
`;

const DIALOG_WIDTH = 500;

const ExternalCredentialsDialog = ({
    isOpen,
    setIsOpen,
    cardState,
    setCardState,
    options,
}: IExternalCredentialsDialogProps) => {
    const dispatch = useDispatch();

    React.useEffect(() => {
        const fetchData = async () => {
            try {
                const credentials = await dispatch(externalCredentialsApi.get({ service: options.service }));
                if (credentials.api_key) {
                    setCardState('Enabled');
                } else {
                    setCardState('Not configured');
                }
            } catch {
                setCardState('Not configured');
            }
        };

        fetchData();
    }, [cardState]);

    const onSubmit = async (formData: FormData) => {
        await connectApiKey(formData, options.service);
        setCardState('Enabled');
        Toaster.show({
            intent: Intent.SUCCESS,
            message: `Successfully connected to ${options.title}`,
            timeout: 5000,
        });
        setIsOpen(false);
    };

    const connectApiKey = (formData: FormData, service: string) =>
        dispatch(
            externalCredentialsApi.create({
                service,
                api_key: formData.api_key,
            }),
        );

    const getFormErrorMessages = (formError) => {
        if (formError) {
            if (formError.includes('IP Restrictions Set')) {
                return [
                    'Your API Key appears to have an IP restriction. Please ensure that your API key has no IP restrictions under Optional Settings in Nearmap and try again.',
                ];
            }
            return ['Your API Key appears to be invalid. Please try again or contact support@helioscope.com'];
        }
        return [];
    };

    const exceptionHandler = (exc) => {
        const { error: formError = null, ...fieldErrors } = exc.response.body;

        const formErrors = getFormErrorMessages(formError);
        return {
            fieldErrors,
            formErrors,
        };
    };

    return (
        <>
            {isOpen && (
                <Form baseValue={{ api_key: '' }} exceptionHandler={exceptionHandler} onSubmit={onSubmit}>
                    {({ clearForm, formData, formErrors, submitting, submitForm }) => {
                        const onClose = () => {
                            clearForm();
                            setIsOpen(false);
                        };

                        return (
                            <Dialog
                                isOpen={isOpen}
                                onClose={onClose}
                                style={{ width: DIALOG_WIDTH }}
                                title={options.title}
                            >
                                <div className={Classes.DIALOG_BODY}>
                                    {formErrors.length > 0 && (
                                        <FormErrorCallout errorMsg={formErrors[0]} style={{ marginBottom: 10 }} />
                                    )}
                                    <DialogContent>{options.dialogInstructions}</DialogContent>
                                    <div style={{ alignSelf: 'stretch' }}>
                                        <FormPasswordInput path="api_key" label="API Key" bold />
                                    </div>
                                </div>

                                <div className={classNames(Classes.DIALOG_FOOTER, Classes.DIALOG_FOOTER_ACTIONS)}>
                                    <Button intent={Intent.NONE} onClick={onClose} text="Cancel" />
                                    <Button
                                        intent={Intent.PRIMARY}
                                        onClick={() => submitForm(formData)}
                                        text="Connect"
                                        loading={submitting}
                                        disabled={submitting || !formData.api_key}
                                    />
                                </div>
                            </Dialog>
                        );
                    }}
                </Form>
            )}
        </>
    );
};

export default ExternalCredentialsDialog;
