import * as React from 'react';
import { connect } from 'react-redux';
import { withRoute } from 'react-router5';

import { IconNames } from '@blueprintjs/icons';
import { Icon, Popover, PopoverInteractionKind, ProgressBar, Spinner } from '@blueprintjs/core';

import Colors from 'reports/styles/colors';
import { FormattedNumber, Power } from 'reports/components/core/numbers';
import { Button, DeleteButton, Link, PersistentTooltip } from 'reports/components/core/controls';

import * as proj from 'reports/models/project';
import * as sim from 'reports/models/simulation';
import { selectors as activitySelectors } from 'reports/modules/activity';
import { selectors as userSelectors } from 'reports/modules/auth';
import { actions as projActions, selectors as projSelectors } from 'reports/modules/project';
import { IAppState, IWithRouteProps } from 'reports/types';
import { bindActions } from 'reports/utils/redux';

type IStateProps = ReturnType<typeof mapStateToProps>;
type IDispatchProps = ReturnType<typeof mapDispatchToProps>;
type IOwnProps = { project?: proj.Project };
type IProps = IOwnProps & IStateProps & IDispatchProps & IWithRouteProps;

class ContextBarActivity extends React.PureComponent<IProps> {
    render() {
        const { project, primaryDesign, primaryScenario, primarySimulation, route, user } = this.props;

        let hasActivity = false;
        let hasSimulateBtn = false;

        const simButton = () => {
            if (!project) return null;

            if (!primaryDesign?.isComplete()) {
                return (
                    <div>
                        <Button icon={IconNames.WARNING_SIGN} text="Incomplete design" disabled fill />
                    </div>
                );
            }

            if (!primaryScenario) {
                return (
                    <div>
                        <Button icon={IconNames.WARNING_SIGN} text="Missing condition set" disabled fill />
                    </div>
                );
            }

            // Generally, if the design is over 15Mw we will only store metadata and not allow simulations
            // However, we allow a per user setting to simulate larger nameplates
            if (!primaryDesign.canSimulate(user.nameplate_limit)) {
                return (
                    <div>
                        <Button
                            icon={IconNames.WARNING_SIGN}
                            disabled
                            fill
                            text={
                                <div>
                                    Primary design nameplate exceeds your nameplate limit of{' '}
                                    <Power value={user.nameplate_limit || 0} precision={0} />
                                    p.
                                    <br />
                                    To run a simulation,{' '}
                                    <Link
                                        routeName="app.projects.project.designer"
                                        routeParams={{
                                            projectId: project.project_id,
                                            designId: primaryDesign.design_id,
                                        }}
                                    >
                                        adjust this design{' '}
                                    </Link>
                                    in the designer.
                                </div>
                            }
                        />
                    </div>
                );
            }
            if (!primarySimulation) {
                hasSimulateBtn = true;

                return (
                    <div>
                        <Button
                            icon={IconNames.PLAY}
                            text="Simulate"
                            fill
                            onClick={() => this.props.simulate(project)}
                        />
                    </div>
                );
            }

            const deleteButton = (
                <DeleteButton
                    style={{ float: 'right' }}
                    small
                    minimal
                    onClick={() => this.props.deleteSimulation(primarySimulation)}
                />
            );

            const { metadata, design } = primarySimulation;

            if (!metadata.total_slices) {
                hasActivity = true;

                return (
                    <div>
                        <div style={{ margin: '8px 0px' }}>Initializing... {deleteButton}</div>
                        <ProgressBar value={0.0} />
                    </div>
                );
            }

            if (design && metadata.complete_slices === metadata.total_slices) {
                const specificYield = metadata.grid_power / design.field_component_metadata.nameplate;

                return (
                    <div>
                        <FormattedNumber value={specificYield} precision={1} /> kWh/kWp {deleteButton}
                    </div>
                );
            }

            if (metadata.error_slices === 0) {
                hasActivity = true;

                return (
                    <div>
                        <div style={{ margin: '8px 0px' }}>Simulating... {deleteButton}</div>
                        <ProgressBar value={metadata.complete_slices / metadata.total_slices} />
                    </div>
                );
            }

            if (metadata.error_slices) {
                return (
                    <div>
                        <Icon icon={IconNames.WARNING_SIGN} style={{ marginRight: '8px' }} />
                        (Simulation error) {deleteButton}
                    </div>
                );
            }

            return null;
        };

        const activityList = () => {
            const { activity } = this.props;
            const items = Object.values(activity).map((i, idx) => (
                <div key={idx}>
                    <div style={{ margin: '8px 0px' }}>{i.text}</div>
                    <ProgressBar />
                </div>
            ));

            if (items.length) hasActivity = true;
            return items.length ? items : null;
        };

        const content = () => {
            const elesim = simButton();
            const eleactivity = activityList();

            return (
                <div className="activity-popover">
                    {elesim}
                    {eleactivity}
                    {!elesim && !eleactivity ? (
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            <i>no activity</i>
                        </div>
                    ) : null}
                </div>
            );
        };

        const target = () => {
            return (
                <div className="activity-target-inner">
                    {hasActivity ? <Spinner size={20} /> : <Icon icon={IconNames.CALCULATOR} iconSize={20} />}
                </div>
            );
        };

        const ct = content();
        const tgt = target();

        return (
            <PersistentTooltip
                content="Run simulation to complete design"
                disabled={!hasSimulateBtn || route.name === 'app.projects.project.designer'}
                placement="bottom"
                persistenceKey={`run_sim_complete_design.${project?.project_id}`}
            >
                <Popover
                    targetProps={{
                        style: {
                            width: '50px',
                            height: '100%',
                            backgroundColor: hasSimulateBtn ? Colors.HELIO_ORANGE : '#444444',
                            color: hasSimulateBtn ? '#ffffff' : '#888888',
                        },
                    }}
                    interactionKind={PopoverInteractionKind.HOVER}
                    modifiers={{ preventOverflow: { enabled: true } }}
                    hoverCloseDelay={300}
                    hoverOpenDelay={10}
                    minimal
                >
                    {tgt}
                    {ct}
                </Popover>
            </PersistentTooltip>
        );
    }
}

const mapStateToProps = (state: IAppState, ownProps: { project?: proj.Project }) => ({
    primaryDesign: projSelectors.primaryDesign(state, ownProps),
    primaryScenario: projSelectors.primaryScenario(state, ownProps),
    primarySimulation: projSelectors.primarySimulation(state, ownProps),
    primaryFinConfig: projSelectors.primaryProjectFinancialTemplate(state, ownProps),
    activity: activitySelectors.allActivityItems(state),
    user: userSelectors.getUser(state)!,
});

const mapDispatchToProps = bindActions({
    simulate: (project) => projActions.triggerPrimarySimulation(project.project_id),
    deleteSimulation: (simulation) => sim.api.delete({ simulation_id: simulation.simulation_id }),
});

export default connect<IStateProps, IDispatchProps>(mapStateToProps, mapDispatchToProps)(withRoute(ContextBarActivity));
