import * as React from 'react';

import ContractView from 'reports/modules/settings/admin/views/ContractView';
import SelfServeView from 'reports/modules/settings/admin/views/SelfServeView';
import TrialView from 'reports/modules/settings/admin/views/TrialView';

import { Subscription } from 'reports/models/subscription';
import { Team } from 'reports/models/team';
import { User } from 'reports/models/user';

interface Props {
    createContract: boolean;
    subscription?: Subscription;
    team: Team;
    user: User;
}

const SubscriptionView = ({ createContract, subscription, team, user }: Props) => {
    const isContract = subscription?.is_contract || createContract;

    const isSelfServe = !!subscription && subscription.is_self_serve;

    const isTrial = team.subscription_type === 'none';

    if (isContract) {
        return <ContractView createContract={createContract} subscription={subscription} user={user} />;
    }

    if (!!subscription && isSelfServe) {
        return <SelfServeView subscription={subscription} team={team} user={user} />;
    }

    if (isTrial) {
        return <TrialView team={team} user={user} />;
    }

    return <div>Not supported!</div>;
};

export { SubscriptionView };
